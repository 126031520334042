import React from 'react';
import { useInfluencerProfile } from 'shared/contexts';
import { InfluencerAttributeValuePair, InfluencerCard, InfluencerLink } from 'components';
import { Divider, Stack } from '@mui/material';

export const InfluencerProfileContact = () => {
  const { influencerProfile } = useInfluencerProfile()

  if (!influencerProfile) {
    return (<></>);
  }

  return (
    <InfluencerCard
      title='Contact Info'
    >
      <Stack spacing={1}>
        {influencerProfile.contactEmail ? (
          <>
            <InfluencerAttributeValuePair
              attribute='Collaborate using email'
              spaceBetween
              value={`mailto:${influencerProfile.contactEmail}`}
              valueLabel={influencerProfile.contactEmail}
              variant='link'
            />
            <Divider />
          </>
        ) : null}
        {influencerProfile.linkToProfile ? (
          <>
            <InfluencerAttributeValuePair
              attribute='Collaborate using direct message'
              spaceBetween
              value={influencerProfile.linkToProfile}
              valueLabel={`${influencerProfile.platformType} Profile`}
              variant='link'
            />
            <Divider />
          </>
        ) : null}
        <InfluencerLink href={influencerProfile.website} label='Website' />
      </Stack>
    </InfluencerCard>
  )
}