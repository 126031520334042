import React from 'react'
import { Drawer, IconButton, Divider, List, ListItem, ListItemButton, ListItemText, styled, useTheme, Button, Box } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { DASHBOARD } from 'navigation/Endpoints';
import { useNavigate } from 'react-router-dom';
import { InfluencerDrawerUserBox } from './InfluencerDrawerUserBox';
import { NavigationItem, getUserNavigationItems } from './utils';
import { useUser } from 'shared/contexts';

const DRAWER_WIDTH = '60vw'

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

interface InfluencerDrawerProps {
  onClose(): void;
  open: boolean;
}

export const InfluencerDrawer = (props: InfluencerDrawerProps) => {
  const { open, onClose } = props;
  const [navigationItems, setNavigationItems] = React.useState<NavigationItem[]>([]);
  const { user } = useUser();
  const theme = useTheme();
  const navigate = useNavigate();

  React.useEffect(() => setNavigationItems(getUserNavigationItems(user)), [user])

  const handleNavigate = (to: string) => () => {
    navigate(to)
    onClose()
  }

  return (
    <Drawer
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: DRAWER_WIDTH,
          boxSizing: 'border-box',
        },
      }}
      anchor="left"
      open={open}
      onClose={onClose}
    >
      <DrawerHeader>
        <Button
          color="inherit"
          onClick={handleNavigate(DASHBOARD)}
        >
          <img
            src={`${process.env.PUBLIC_URL}/logo-dark.webp`}
            alt='TrendMyAd logo'
            width={120}
          />
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton onClick={onClose}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <InfluencerDrawerUserBox />
      <Divider />
      <List>
        {navigationItems.map(({ label, endpoint}) => (
          <ListItem disablePadding key={endpoint}>
            <ListItemButton onClick={handleNavigate(endpoint)}>
              <ListItemText primary={label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </Drawer>
  )
}