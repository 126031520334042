import React, { useReducer, ReactNode } from 'react';
import { InfluencerContext } from './influencerContext';
import { Influencer } from './influencerContextTypes';
import { reducer } from '../utils';

const initialState: Influencer = {
  _id: '',
  username: '',
  platformType: ''
};

interface InfluencerProviderProps {
  children?: ReactNode
}

export const InfluencerProvider = (props: InfluencerProviderProps) => {
  const [influencer, setInfluencer] = useReducer(reducer<Influencer>, initialState);

  return (
    <InfluencerContext.Provider value={{ influencer, setInfluencer }}>
      {props.children}
    </InfluencerContext.Provider>
  );
}
