import React, { useState } from 'react';
import { InfluencerButton, InfluencerSubmitButton, InfluencerDialogSelect, InfluencerPriceField, InfluencerTextField } from 'components';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormProps } from 'shared/utils';
import { Card, CardContent, Grid } from '@mui/material';
import { useGetAdTypes } from 'shared/hooks';

interface InfluencerPrice {
  adTypeId: string;
  value?: number;
};

export interface InfluencerPricesFormInputs {
  prices: InfluencerPrice[]

};

export const InfluencerPricesForm = (props: FormProps<InfluencerPricesFormInputs>) => {
  const { loading: adTypesLoading, responseData: adTypes } = useGetAdTypes();
  const [ submitedPrices, setSubmitedPrices ] = useState<InfluencerPrice[]>([])

  const {
    control,
    getValues,
    handleSubmit, 
    formState: { errors, isValid },
    register,
    setValue,
    trigger,
  } = useFormContext<InfluencerPricesFormInputs>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'prices'
  });

  const onSubmit = async (data: InfluencerPricesFormInputs) => {
    if (data.prices.length === 0) return;
    await props.onSubmit(data)
      .then(() => {
        setSubmitedPrices([...submitedPrices, ...getValues('prices')]);
        remove();
      })
      
  }

  const getAdTypeValue = (adTypeId: string): string | undefined => {
    const adType = adTypes?.find(adType => adType._id === adTypeId)

    return adType && `${adType.name} - ${adType.description}`
  }

  const isSubmitDisabled = () => {
    return props.loading || adTypesLoading || !isValid || getValues('prices').length === 0
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container justifyContent="center" spacing={7}>
        <Grid item xs={12}>
          <Grid container spacing={7}>
            {submitedPrices.map((item, index) => (
              <Grid item md={4} sm={6} xs={12} textAlign='center' key={`price-box-${index}-submitted`}>
                <Card>
                  <CardContent>
                    <InfluencerTextField
                      disabled={true}
                      label='Ad Type'
                      value={item.adTypeId && getAdTypeValue(item.adTypeId)}
                    />
                    <InfluencerPriceField
                      disabled={true}
                      label='Price'
                      value={item.value}
                    /> 
                    <InfluencerButton
                      disabled={true}
                      label='Remove Price Entry'
                    />
                  </CardContent>
                </Card>
              </Grid>
            )) }
            {fields.map((item, index) => (
              <Grid item md={4} sm={6} xs={12} textAlign='center' key={`price-box-${index}`}>
                <Card>
                  <CardContent>
                    <InfluencerDialogSelect
                      dialogCardDescriptions={adTypes?.map(adType => adType.description) || []}
                      dialogCardHeaders={adTypes?.map(adType => adType.name) || []}
                      dialogCardKeys={adTypes?.map(adType => adType._id) || []}
                      dialogTitle={'Pick one of the ad types'}
                      disabled={adTypesLoading}
                      errors={errors.prices?.[index]?.adTypeId}
                      isRequired
                      label='Ad Type'
                      name={`prices.${index}.adTypeId`}
                      register={register}
                      setValue={(adTypeId) => {
                        setValue(`prices.${index}.adTypeId`, adTypeId);
                        trigger(`prices.${index}.adTypeId`);
                      }}
                    />
                    <InfluencerPriceField
                      disabled={props.loading}
                      errors={errors.prices?.[index]?.value}
                      isRequired
                      label='Price'
                      name={`prices.${index}.value`}
                      register={register}
                    /> 
                    <InfluencerButton
                      label='Remove Price Entry'
                      loading={props.loading}
                      onClick={() => remove(index)}
                    />
                  </CardContent>
                </Card>
              </Grid>
            ))}
            </Grid>
          </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <InfluencerButton
            label='Add Price Entry'
            loading={props.loading || adTypesLoading}
            onClick={() => append({adTypeId: ''})}
          />
          <InfluencerSubmitButton
            label='Submit Prices'
            disabled={isSubmitDisabled()}
            loading={props.loading || adTypesLoading}
          />
        </Grid>
      </Grid>
    </form> 
  )
}