import { useContext } from 'react';
import { InfluencerProfileContext } from './influencerProfileContext';

export const useInfluencerProfile = () => {
  const influencerProfileContext = useContext(InfluencerProfileContext);

  if (!influencerProfileContext) {
    throw new Error('useInfluencerProfile must be used within an InfluencerProfileProvider');
  }

  return influencerProfileContext;
};