import React, { useState } from 'react';
import { InfluencerSubmitButton, InfluencerLoader, InfluencerImageListItem } from 'components';
import { FieldName, FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';
import { FormProps } from 'shared/utils';
import { Grid, ImageList } from '@mui/material';
import { useGetTopics } from 'shared/hooks';

export interface InfluencerTopicsFormInputs {
  topics: string[]
};

export const InfluencerTopicsForm = (props: FormProps<InfluencerTopicsFormInputs>) => {
  const { loading: topicsLoading, responseData: topics } = useGetTopics();
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);

  const {
    handleSubmit, 
    formState: { isValid },
    register,
    setValue,
    trigger,
  } = useFormContext<InfluencerTopicsFormInputs>();

  const handleImageSelection = (eventTopic: string) => {
    const updatedSelectedTopics = selectedTopics.some((topic) => topic === eventTopic)
      ? selectedTopics.filter((topic) => topic !== eventTopic)
      : [...selectedTopics, eventTopic]

    setSelectedTopics(updatedSelectedTopics);
    setValue('topics', updatedSelectedTopics);
    trigger('topics');
  };

  const validationOptions: RegisterOptions<
    FieldValues,
    FieldName<FieldValues>
  > = {};
  
  validationOptions.required = 'Select at least one topic';
  register('topics', validationOptions);

  // TODO REMOVE THIS SHIT ASAP
  const onSubmit = async (data: InfluencerTopicsFormInputs) => {
    const mappedData = {
      topics: data.topics.map(t1 => topics?.find(t2 => t1 === t2.name)?._id || '') || []
    };
    await props.onSubmit(mappedData)
  }

  return (
    // This must be a separate UI component, for example, Image Picker with it's own logic
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container justifyContent="center" spacing={7}>
        <Grid item xs={12}>
          <ImageList
            sx={{ padding: 1 }}
            variant="quilted"
            cols={3}
            rowHeight={300}
          >
            {topicsLoading || !topics ? (<InfluencerLoader />) : topics.map((topic) => (
              <InfluencerImageListItem
                key={topic.name}
                onClick={() => handleImageSelection(topic.name)}
                selected={selectedTopics.some((selectedTopic) => selectedTopic === topic.name)}
                src={`${process.env.PUBLIC_URL}/images/influencerTopics/${topic.name}.jpg`}
                title={topic.name}
              />
            ))}
          </ImageList>
          {/* <Grid container spacing={2}>
            {topicsLoading ? (<InfluencerLoader />) : topics?.map((topic) => (
              <Grid item lg={3} md={4} sm={6} xs={12} key={topic.name} >
                <InfluencerImageListItem
                  key={topic.name}
                  onClick={() => handleImageSelection(topic.name)}
                  selected={selectedTopics.some((selectedTopic) => selectedTopic === topic.name)}
                  src={`${process.env.PUBLIC_URL}/images/influencerTopics/${topic.name}.jpg`}
                  title={topic.name}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <FormHelperText error>{errors?.topics?.message || ' '}</FormHelperText>
            </Grid>
          </Grid> */}
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <InfluencerSubmitButton
            label='Submit Topics'
            disabled={props.loading || topicsLoading || !isValid}
            loading={props.loading || topicsLoading}
          />
        </Grid>
      </Grid>
    </form> 
  )
}