import { useGet } from 'shared/utils/api';

const BASE_API = `${process.env.REACT_APP_BASE_API}/topics`;

interface GetTopicsResponse {
  _id: string,
  name: string,
};

const useGetTopics = () => useGet<undefined, GetTopicsResponse[]>(`${BASE_API}/`);

export { useGetTopics, GetTopicsResponse };