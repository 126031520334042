import React from 'react';
import { Stack } from '@mui/material';
import { footerNavigationItems } from 'components/navigation/utils';
import { InfluencerTopMenuButton } from 'components/navigation/TopMenuButton';

export const InfluencerFooter = () => {
  
  return (
      <Stack direction='row' m={3} justifyContent='center'>
        {footerNavigationItems.map(({ label, endpoint, Icon}) => (
          <InfluencerTopMenuButton
            key={label}
            label={label}
            href={endpoint}
            Icon={Icon}
          />
        ))}
      </Stack>
  )
}