// material-ui
import { createTheme } from '@mui/material/styles';

import palette from './palette.scss';

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = () => {
    return createTheme({
        palette: {
            type: 'light',
            primary: {
                main: palette.primary,
            },
            secondary: {
                main: palette.secondary,
            },
            background: {
                default: palette.background,
                paper: palette.paper,
            }
        }
    });
};

export default Palette;