import { useGet } from 'shared/utils/api';

const BASE_API = `${process.env.REACT_APP_BASE_API}/users`;

interface GetCurrentUserResponse {
  id: string,
  name: string,
  isAdmin: boolean,
};

const useGetCurrentUser = () => useGet<undefined, GetCurrentUserResponse>(`${BASE_API}/me/`, undefined, undefined, true);

export { useGetCurrentUser };