import { StandardTextFieldProps } from '@mui/material';
import React from 'react';
import { STextField } from './styled';
import { Control, Controller, FieldError, FieldName, FieldValues, Merge, RegisterOptions, UseFormRegister, ValidationRule } from 'react-hook-form';

export interface InfluencerTextFieldProps extends StandardTextFieldProps {
  name?: string
  errors?: Merge<FieldError, (FieldError | undefined)[]>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: UseFormRegister<any>
  isRequired?: boolean
  minLength?: ValidationRule<number>
  maxLength?: ValidationRule<number>
  min?: ValidationRule<number>
  max?: ValidationRule<number>
  control?: Control
}

export const InfluencerTextField = (props: InfluencerTextFieldProps) => {
  const validationOptions: RegisterOptions<
    FieldValues,
    FieldName<FieldValues>
    > = {};
  
  if (props.isRequired) {
    validationOptions.required = `${props.label} is required`;
  }

  if (typeof props.minLength !== 'undefined') {
    validationOptions.minLength = props.minLength;
  }

  validationOptions.maxLength = props.maxLength || 3000;

  if (typeof props.min !== 'undefined') {
    validationOptions.min = props.min;
  }

  if (props.type === 'number') {
    validationOptions.valueAsNumber = true
  }

  if (typeof props.max !== 'undefined') {
    validationOptions.max = props.max;
  }

  const register = props.register && props.name ? props.register(props.name, validationOptions) : {};

  return props.control ? (
    <Controller
      control={props.control}
      name={props.name || ''}
      rules={validationOptions}
      render={({field: {onChange, value}}) => (
        <STextField
          onChange={onChange} 
          value={value || ''}
          error={!!props.errors}
          helperText={props.errors?.message || ' '}
          fullWidth
          variant='outlined'
          {...props}
        />
      )}
    />
  ) : 
    (
    <STextField
      error={!!props.errors}
      helperText={props.errors?.message || ' '}
      fullWidth
      variant='outlined'
      {...register}
      {...props}
    />
  )
}