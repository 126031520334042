import React from 'react';
import {
  ToastContainer as ToastifyToastContainer,
  toast,
} from 'react-toastify';
import Alert, { AlertColor } from '@mui/material/Alert';
import 'react-toastify/dist/ReactToastify.min.css';

export const ToastContainer = () => (
  <ToastifyToastContainer
    closeButton={false}
    closeOnClick={false}
    position="top-center"
    toastStyle={{
      background: 'transparent',
      boxShadow: 'none',
      padding: 0,
    }}
  />
);

export const openToast = (
  message: string,
  severity: AlertColor,
  action?: React.ReactNode,
  // eslint-disable-next-line no-unused-vars
  onClose?: ((event: React.SyntheticEvent<Element, Event>) => void) | undefined
) => {
  toast(
    () => (
      <Alert
        onClose={onClose}
        elevation={6}
        variant="filled"
        sx={{ color: `${severity}.contrastText` }}
        severity={severity}
        action={action}
      >
        {message}
      </Alert>
    ),
    {
      autoClose: 800,
      hideProgressBar: true,
    }
  );
};

export default { ToastContainer, openToast };
