import React from 'react';
import './dashboardPage.scss';
import { InfluencerSearch } from './features/influencerSearch';

export const DashboardPage = () => {

  return (
    <>
      <InfluencerSearch />
      <div className={'dashboard-background-image'}></div>
    </>
  );
}
