import React, { useState } from 'react';
import { InfluencerCard, InfluencerPagination } from 'components';
import { Grid, Button } from '@mui/material';
import { useGetInfluencerFeedback } from 'shared/hooks/requests/useInfluencerFeedbacks';
import { useInfluencerProfile } from 'shared/contexts';
import { InfluencerProfileFeedbackGridWithLoading } from './InfluencerProfileFeedbackGrid';
import { DEFAULT_PAGINATION_PAGE_SIZE } from 'shared/utils';
import { InfluencerProfileFeedbackDialog } from '../influencerProfileFeedbackDialog';

export const InfluencerProfileFeedback = () => {
  const { influencerProfile } = useInfluencerProfile()

  if (!influencerProfile) {
    return (<></>);
  }

  // const { user } = useUser()

  let currentFeedbackPage = 1;

  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const openFeedbackDialog = () => setFeedbackDialogOpen(true);

  const handleCloseFeedbackDialog = () => setFeedbackDialogOpen(false)

  const handleSuccessfulFeedbackSubmit = () => {
    getInfluencerFeedbackForSpecificPage(currentFeedbackPage)
    setFeedbackDialogOpen(false);
  }

  const {
    apiCall: getInfluencerFeedback,
    responseData: influencerFeedback,
    loading: influencerFeedbackLoading,
    responseItemsTotalCount: influencerFeedbackTotalCount
  } = useGetInfluencerFeedback(influencerProfile._id, { limit: DEFAULT_PAGINATION_PAGE_SIZE, skip: 0 })

  const handlePageChange = (page: number) => {
    getInfluencerFeedbackForSpecificPage(page);
    currentFeedbackPage = page;
  }

  const getInfluencerFeedbackForSpecificPage = (page: number) => {
    const itemsToSkip = DEFAULT_PAGINATION_PAGE_SIZE * (page - 1);
    getInfluencerFeedback({ limit: DEFAULT_PAGINATION_PAGE_SIZE, skip: itemsToSkip });
  }

  return (
    <InfluencerCard
      title='Users Feedback'
    >
      <Grid container spacing={2}>
        <Grid item md={8} sm={7} xs={6} />
        <Grid item md={4} sm={5} xs={6} textAlign='right'>
          {/* { TODO
            user.userAccessToken
              ? (<Button onClick={openFeedbackDialog} variant='contained'>Leave A Feedback</Button>)
              : (<Button variant='text' disabled>Login To Leave A feedback</Button>)
          } */}
          <Button onClick={openFeedbackDialog} variant='contained'>Leave A Feedback</Button>
        </Grid>
        <InfluencerProfileFeedbackGridWithLoading
          influencerFeedback={influencerFeedback}
          loading={influencerFeedbackLoading}
        />
        <Grid item xs={12} display='flex' justifyContent='center'>
          <InfluencerPagination
            totalItems={influencerFeedbackTotalCount || DEFAULT_PAGINATION_PAGE_SIZE}
            pageSize={DEFAULT_PAGINATION_PAGE_SIZE}
            currentPage={currentFeedbackPage}
            onPageChange={handlePageChange}
          />
        </Grid>
      </Grid>
      <InfluencerProfileFeedbackDialog
        onClose={handleCloseFeedbackDialog}
        onSuccessfulSubmit={handleSuccessfulFeedbackSubmit}
        open={feedbackDialogOpen}
      />
    </InfluencerCard>
  )
}