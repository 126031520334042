import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

type InfluencerHomeImageParams = {
  src: string;
  alt: string;
  width?: string | number;
  height?: string | number;
  footer?: React.ReactNode;
  eagerLoad?: boolean;
}

export const InfluencerHomeImage = (params: InfluencerHomeImageParams) => {
  const { src, alt, width, height, eagerLoad } = params

  return eagerLoad
    ? (
      <img
        id={src}
        key={src}
        src={src}
        alt={ alt }
        width={ width || '95%' }
        height={height || '100%'}
        fetchpriority='high'
      ></img>
    ) : (
      <LazyLoadImage
        id={src}
        key={src}
        src={src}
        alt={ alt }
        width={ width || '95%' }
        height={height || '100%'}
        effect='opacity'
        fetchpriority='low'
      />
    );
}