import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUser } from 'shared/contexts';
import { LOGIN } from './Endpoints';

// If user injects cookie, this can be easily bypassed
export const ProtectedRoute = (props: React.PropsWithChildren) => {
  const { pathname } = useLocation()
  const { user } = useUser()

  return (<>
    {user.name ? props.children : <Navigate to={LOGIN} state={{redirectTo: pathname}} replace={true} /> }
  </>);
};