import { styled } from '@mui/material/styles';

export const InfluencersStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.grey[500],
    display: 'flex',
    height: 22,
    zIndex: 1,
    alignItems: 'center',
    ...(ownerState.active && {
      color: theme.palette.grey[50],
    }),
    '& .QontoStepIcon-completedIcon': {
      color: theme.palette.grey[50],
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 14,
      height: 14,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }),
);