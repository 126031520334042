export const ABOUT_US = '/about-us';
export const CONTACT_US = '/contact-us';
export const COOKIE_DECLARATION = '/cookie-declaration';
export const DASHBOARD = '/dashboard';
export const HOME = '/';
export const INFLUENCER_CREATE = '/influencers/create';
export const INFLUENCER_CREATE_ASYNC = '/influencers/create/async';
export const INFLUENCER_PROFILE = '/influencers/:influencerId/profile';
export const LOGIN = '/login';
export const PRIVACY_POLICY = '/privacy-policy';
export const BLOGS = '/blogs';
export const BLOG = '/blogs/:blogId';

