import { Box, Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import React from 'react';
import { Control, Controller, FieldError, Merge } from 'react-hook-form';
import InfoOutlinedIcon from '@mui/icons-material/Info';

export interface InfluencerCheckboxProps {
  disabled?: boolean
  errors?: Merge<FieldError, (FieldError | undefined)[]>
  name: string
  required?: boolean
  label: string
  control?: Control
  tooltip?: string
}

export const InfluencerCheckbox = (props: InfluencerCheckboxProps) =>
  <Controller
    name={props.name}
    control={props.control}
    defaultValue={false} 
    render={({ field }) => (
      <>
        <FormControlLabel
          control={
            <Checkbox
              {...field}
              checked={!!field.value}
              onChange={(e) => field.onChange(e.target.checked)}
              disabled={props.disabled}
              required={props.required}
            />
          }
          label={props.tooltip ? (
            <Box display='flex'>
              <Box>{props.label}</Box>
              <Tooltip title={props.tooltip} placement="top-end">
                <InfoOutlinedIcon sx={{ml: 1, fontSize: 20}}/>
              </Tooltip>
            </Box>
          ) : props.label}
        />
        
      </>
    )}
  />
  