import React from 'react';
import { InfluencerHomeImage, InfluencerHomePaper } from './components';
// import { Language } from 'shared/models';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { InfluencerButton } from 'components';
import { DASHBOARD } from 'navigation/Endpoints';
import { InfluencerSlickSlider } from 'components/slickSlider';

// type RouteParams = {
//   language: Language
// }

export const HomePage = () => {
  // const { language } = useParams<RouteParams>() as RouteParams
  const theme = useTheme()

  const smallerThanLg = useMediaQuery(theme.breakpoints.down('lg'));
  const smallerThanMd = useMediaQuery(theme.breakpoints.down('md'));

  const textVariant = smallerThanMd ? 'h6' : 'h5';
  const headerTextVariant = smallerThanMd ? 'h2' : 'h1';
   
  return (
    <Stack alignItems='center'>
      <InfluencerHomePaper
        direction='row'
      >
        {smallerThanMd
          ? null
          : (
            <Box>
              <InfluencerHomeImage
                src={`${process.env.PUBLIC_URL}/images/home-page/dashboard_mockup.webp`}
                alt='TrendMyAd dashboard mockup'
                width={smallerThanLg ? 376 : 607}
                height={smallerThanLg ? 432 : 697}
                eagerLoad
              />
            </Box>
          )}
        <Stack spacing={8} textAlign="left">
          <Box>
            <Typography variant={headerTextVariant} color={theme.palette.primary.main} >
              Empower Your Brand with
            </Typography>
            <Typography variant={headerTextVariant} >
              <InfluencerSlickSlider slidesToShow={1}>
                <Box>Community-Driven</Box>
                <Box>Free of Charge</Box>
                <Box>Data Rich</Box>
              </InfluencerSlickSlider>
            </Typography>
            <Typography variant={headerTextVariant} color={theme.palette.primary.main}>
              Influencer Marketing Tool!
            </Typography>
          </Box>
          {/* <Typography variant={textVariant} textAlign='left'>
            Whether you&apos;re seeking the perfect individual to promote your brand or product, or simply curious about the world of influencer marketing, our platform is your go-to resource.
          </Typography> */}
          <Typography variant={textVariant} textAlign='left'>
            Discover, Compare, and Connect with Instagram Influencers
          </Typography>
          <Box>
            <InfluencerButton label='Start Now!' fullWidth={smallerThanLg} color='primary' href={DASHBOARD} textVariant='h6' />
          </Box>
        </Stack>
      </InfluencerHomePaper>
      <InfluencerHomePaper
        direction='row'
        hideDivider
        backgroundColor={theme.palette.background.default}
      >
        <InfluencerHomeImage
          src={`${process.env.PUBLIC_URL}/images/home-page/influencers_ref.webp`}
          alt='TrendMyAd influencers reference photo'
          width={smallerThanLg ? 332 : 664}
          height={smallerThanLg ? 429 : 858}
          eagerLoad={smallerThanMd}
        />
        <InfluencerHomePaper
          direction='column'
          backgroundColor={theme.palette.background.default}
        >
          <Typography width={300} textAlign='right'>
            <Typography variant={headerTextVariant}>2.4K</Typography>
            <Typography variant={textVariant}>2488 Instagram profiles indexed so far</Typography>
          </Typography>
          <Typography width={300} textAlign='right'>
            <Typography variant={headerTextVariant}>1.1K</Typography>
            <Typography variant={textVariant}>We collected 1100 nano influencers profiles with less than 5k followers so far</Typography>
          </Typography>
          <Typography width={300} textAlign='right'>
            <Typography variant={headerTextVariant}>1</Typography>
            <Typography variant={textVariant}>First supported country is Lithuania and its influencers</Typography>
          </Typography>
        </InfluencerHomePaper>
      </InfluencerHomePaper>
      <InfluencerHomePaper
        direction='column'
        hideDivider
        backgroundColor={theme.palette.primary.main}
        backgroundImage={`${process.env.PUBLIC_URL}/images/home-page/path_1.webp`}
        spacing={20}
        width={smallerThanLg ? 1 : 0.64}
        header={
          <Typography variant={headerTextVariant}>How it works?</Typography>
        }
      >
        <Box />
        <Stack direction={smallerThanMd ? 'column' : 'row'} alignItems='center' justifyContent='space-between' width={1} spacing={5}>
          <InfluencerHomeImage
            src={`${process.env.PUBLIC_URL}/images/home-page/search_bar_mockup.webp`}
            alt='TrendMyAd search bar mockup'
            width={smallerThanLg ? '95%' : 650}
          />
          <Typography variant={textVariant} textAlign='left' width={smallerThanLg ? '90%' : 400}>
            <Typography variant={'inherit'} fontWeight={800} >SEARCH</Typography>
            Use TrenMyAd dashboard to find influencers that fit your brand’s needs.
          </Typography>
        </Stack>
        <Stack direction={smallerThanMd ? 'column' : 'row'}  alignItems='center' justifyContent='space-between' width={1} spacing={5}>
          <InfluencerHomeImage
            src={`${process.env.PUBLIC_URL}/images/home-page/data_mockup.webp`}
            alt='TrendMyAd data mockup'
            width={smallerThanLg ? '95%' : 600}
          />
          <Typography variant={textVariant} textAlign='left' width={smallerThanLg ? '95%' : 400}>
            <Typography variant={'inherit'} fontWeight={800} >COMPARE</Typography>
            View and compare influencer pricing, engagement rates and more to find the best match for your needs.
          </Typography>
        </Stack>
        <Stack direction={smallerThanMd ? 'column' : 'row'} alignItems='center' justifyContent='space-between' width={1} spacing={5}>
          <InfluencerHomeImage
            src={`${process.env.PUBLIC_URL}/images/home-page/reviews_mockup.webp`}
            alt='TrendMyAd reviews mockup'
            width={smallerThanLg ? '95%' : 600}
          />
          <Typography variant={textVariant} textAlign='left' width={smallerThanLg ? '95%' : 400}>
            <Typography variant={'inherit'} fontWeight={800} >VERIFY</Typography>
            Check out feedback from our community to ensure you’re making the right choice.
          </Typography>
        </Stack>
        <Stack direction={smallerThanMd ? 'column' : 'row'}  alignItems='center' justifyContent='space-between' width={1} spacing={5}>
          <InfluencerHomeImage
            src={`${process.env.PUBLIC_URL}/images/home-page/contact_info_mockup.webp`}
            alt='TrendMyAd contact info mockup'
            width={smallerThanLg ? '95%' : 600}
          />
          <Typography variant={textVariant} textAlign='left' width={smallerThanLg ? '95%' : 400}>
            <Typography variant={'inherit'} fontWeight={800} >CONTACT</Typography>
            Reach out to your chosen influencers and start building impactful campaigns.
          </Typography>
        </Stack>
        <Box />
      </InfluencerHomePaper>
      <InfluencerHomePaper
        direction='column'
        backgroundColor={theme.palette.background.default}
        hideDivider
        width={smallerThanLg ? 0.9 : 0.6}
        header={
          <Typography variant={headerTextVariant}>Key Features</Typography>
        }
      >
        <InfluencerHomePaper direction='row'>
          <InfluencerHomeImage
            src={`${process.env.PUBLIC_URL}/images/home-page/search_icon.webp`}
            alt='TrendMyAd search icon'
            width={100}
          />
          <Typography variant={textVariant} textAlign='left'>
            <Typography variant={'inherit'} fontWeight={600} >Discover Top Influencers</Typography>
            Search influencers based on your specific needs, including price, topics, and more.
          </Typography>
        </InfluencerHomePaper>
        <InfluencerHomePaper direction='row'>
          <InfluencerHomeImage
            src={`${process.env.PUBLIC_URL}/images/home-page/feedback_icon.webp`}
            alt='TrendMyAd feedback icon'
            width={100}
          />
          <Typography variant={textVariant} textAlign='left' >
            <Typography variant={'inherit'} fontWeight={600} >Community-Driven Reviews</Typography>
            Find honest feedback and evaluations from our active community of brands and individuals, benefiting from the collective experience and insights of the marketing community.
          </Typography>
        </InfluencerHomePaper>
        <InfluencerHomePaper direction='row'>
          <InfluencerHomeImage
            src={`${process.env.PUBLIC_URL}/images/home-page/stats_icon.webp`}
            alt='TrendMyAd stats icon'
            width={100}
          />
          <Typography variant={textVariant} textAlign='left' >
            <Typography variant={'inherit'} fontWeight={600} >Detailed Statistics</Typography>
            Access comprehensive data on influencer performance to make informed decisions.
          </Typography>
        </InfluencerHomePaper>
        <InfluencerHomePaper direction='row'>
          <InfluencerHomeImage
            src={`${process.env.PUBLIC_URL}/images/home-page/profile_icon.webp`}
            alt='TrendMyAd profile icon'
            width={100}
          />
          <Typography variant={textVariant} textAlign='left' >
            <Typography variant={'inherit'} fontWeight={600} >Indexing Small Profiles</Typography>
            We index even small profiles with at least 1k followers, public profiles, and those marked as creator or business accounts. This allows you to find not only top-tier profiles but also smaller, more affordable ones.
          </Typography>
        </InfluencerHomePaper>
        <InfluencerHomePaper direction='row' textAlign='left'>
          <InfluencerHomeImage
            src={`${process.env.PUBLIC_URL}/images/home-page/stats_icon.webp`}
            alt='TrendMyAd stats icon'
            width={100}
          />
          <Typography variant={textVariant} textAlign='left' width={1}>
            <Typography variant={'inherit'} fontWeight={600}>Free to Use</Typography>
            Enjoy all the features of TrendMyAd.com at no cost.
          </Typography>
        </InfluencerHomePaper>
      </InfluencerHomePaper>
      <InfluencerHomePaper
        direction='row'
        hideDivider
        header={
          <Typography variant={headerTextVariant}>Why to choose a <span style={{ color: theme.palette.primary.main }}>community-driven</span> platform?</Typography>
        }
      >
        <Box>
          <InfluencerHomePaper direction='row' textAlign='left'>
            <Typography variant={textVariant} fontWeight={600} width={130}>Authenticity</Typography>
            <Typography variant={textVariant} textAlign='left' width={300}>
              Real feedback from real users provides genuine insights.
            </Typography>
          </InfluencerHomePaper>
          <InfluencerHomePaper direction='row' textAlign='left'>
            <Typography variant={textVariant} fontWeight={600} width={130}>Accuracy</Typography>
            <Typography variant={textVariant} textAlign='left' width={300}>
              Data contributed by the community ensures up-to-date and precise information.
            </Typography>
          </InfluencerHomePaper>
          <InfluencerHomePaper direction='row' textAlign='left'>
            <Typography variant={textVariant} fontWeight={600} width={130}>Trust</Typography>
            <Typography variant={textVariant} textAlign='left' width={300}>
              Transparency and honesty in reviews build trust and reliability.
            </Typography>
          </InfluencerHomePaper>
        </Box>
        <InfluencerHomeImage
            src={`${process.env.PUBLIC_URL}/images/home-page/community_ref.webp`}
            alt='TrendMyAd community reference photo'
            width={smallerThanLg ? smallerThanMd ? '80%' : '100%' : '70%'}
          />
      </InfluencerHomePaper>
    </Stack>
  );
}