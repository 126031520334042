import React from 'react';
import { InfluencerAttributeValuePair, InfluencerCard, InfluencerRating } from 'components';
import { Box, Divider, Grid } from '@mui/material';
import { GetInfluencerFeedbackResponse } from 'shared/hooks/requests/useInfluencerFeedbacks';
import { withLoading } from 'shared/hoc';

interface InfluencerProfileFeedbackGridProps {
  influencerFeedback?: GetInfluencerFeedbackResponse[];
}

const InfluencerProfileFeedbackGrid = (props: InfluencerProfileFeedbackGridProps) => {
  const { influencerFeedback } = props;

  return (
    <>
      {influencerFeedback?.map((feedbackItem) => (
        <Grid item md={4} sm={6} xs={12} textAlign='center' key={`feedback-box-${feedbackItem._id}`}>
          <InfluencerCard
            hideDivider={true}
            variant='subcard'
          >
            {feedbackItem.rating && <InfluencerRating readOnly={true} value={feedbackItem.rating} />}
            
            <InfluencerAttributeValuePair
              value={feedbackItem.comment}
              variant='longText' 
            />
            {
              feedbackItem.prices.length
                ? <Divider sx={{ mt: 2, mb: 2 }} />
                : null
            }
            {feedbackItem.prices?.map(price => (
              <Box key={price.adType}>
                <InfluencerAttributeValuePair
                  attribute={price.adType}
                  spaceBetween
                  value={price.value}
                  variant='money'
                />
              </Box>
            ))}
            <Divider sx={{ mt: 2, mb: 2 }} />
            <InfluencerAttributeValuePair
              value={feedbackItem.timestamp}
              justifyContent='end'
              variant='date'
            />
          </InfluencerCard>
        </Grid>
      ))}
    </>
  )
}

export const InfluencerProfileFeedbackGridWithLoading = withLoading(InfluencerProfileFeedbackGrid)