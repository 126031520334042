import React from 'react'
import { IconButton, Tooltip } from '@mui/material'

interface InfluencerTooltipIconProps {
  disabled?: boolean,
  iconComponent: React.ReactNode,
  onClick(): void,
  tooltipTitle: string,
}

export const InfluencerTooltipIcon = (props: InfluencerTooltipIconProps) => {
  const { disabled, iconComponent, onClick, tooltipTitle } = props
  
  return (
    <Tooltip title={tooltipTitle}>
      <span aria-label={undefined}>
        <IconButton
          onClick={onClick}
          disabled={disabled}
          color='inherit'
          aria-label={tooltipTitle}
        >
          { iconComponent }
        </IconButton>
      </span>
    </Tooltip>
  )
}