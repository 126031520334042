import axios, { Method } from 'axios';
import { openToast } from 'components';
import { useState, useEffect, useCallback } from 'react';
import { useUser } from 'shared/contexts';
import qs from 'qs';

interface ApiResponse<Request, Response> {
  // eslint-disable-next-line no-unused-vars
  apiCall(request?: Request, successMessage?: string, header?: Partial<Header>): Promise<Response>;
  loading: boolean;
  error: string | undefined;
  responseData: Response | undefined;
  responseItemsTotalCount: number | undefined;
}

interface Header {
  'user-access-token': string
}

const useGet = <Request, Response>(url: string, payload?: Request, headers?: Partial<Header>, onDemand = false): ApiResponse<Request, Response> => {
  return performRequest<Request, Response>(url, 'GET', onDemand, headers, payload);
}

const usePost = <Request, Response>(url: string, headers?: Partial<Header>, onDemand = true): ApiResponse<Request, Response> => {
  return performRequest<Request, Response>(url, 'POST', onDemand, headers);
}

const usePut = <Request, Response>(url: string, headers?: Partial<Header>, onDemand = true): ApiResponse<Request, Response> => {
  return performRequest<Request, Response>(url, 'PUT', onDemand, headers);
}

const performRequest = <Request, Response>(url: string, method: Method, onDemand: boolean, headers?: Partial<Header>, payload?: Request, successMessage?: string): ApiResponse<Request, Response> => {
  const [responseData, setResponseData] = useState<Response>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [responseItemsTotalCount, setResponseItemsTotalCount] = useState<number>();
  const { user } = useUser();

  // TODO handle errors
  // TODO set default header on user login
  const apiCall = useCallback((payload?: Request, successMessage?: string, header?: Partial<Header>) => {
    setLoading(true);
    return axios.request({
      data: method === 'GET' ? undefined : payload,
      headers: {
        'user-access-token': user.userAccessToken,
        ...header
      },
      method,
      url,
      params: method === 'GET' ? payload : undefined,
      paramsSerializer: {
        serialize: params => {
          return qs.stringify(params, { indices: false })
        }
      }
    }).then(response => {
      setResponseData(response.data)
      setResponseItemsTotalCount(Number(response.headers['x-total-count']))
      successMessage && openToast(successMessage, 'success')
      return response.data
    }).catch(error => {
      setError(error)
      return Promise.reject(error)
    }).finally(() => setLoading(false))
  }, [user])

  useEffect(() => { onDemand ? setLoading(false) : apiCall(payload, successMessage, headers) }, [])

  return { apiCall, error, loading, responseData, responseItemsTotalCount };
};

export { useGet, usePost, usePut };
