import React, { useReducer, ReactNode, useEffect } from 'react';
import { UserContext } from './userContext';
import { User } from './userContextTypes';
import { reducer } from '../utils';

const initialState: User = {};
const localState = localStorage.getItem('user')
const localStateParsed = localState ? JSON.parse(localState) : null;

interface UserProviderProps {
  children?: ReactNode
}

export const UserProvider = (props: UserProviderProps) => {  
  const [user, setUser] = useReducer(reducer<User>, localStateParsed || initialState);

  // TODO initiate storage constants, storage utils  
  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user));
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {props.children}
    </UserContext.Provider>
  );
}
