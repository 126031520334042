import React from 'react';
import { useInfluencerProfile } from 'shared/contexts';
import { InfluencerAttributeValuePair, InfluencerCard } from 'components';
import { Box, Divider } from '@mui/material';

export const InfluencerProfileInsights = () => {
  const { influencerProfile } = useInfluencerProfile()

  if (!influencerProfile) {
    return (<></>);
  }

  return (
    <InfluencerCard
      title='What Items Influencer Could Promote?'
    >
      {
        influencerProfile.insights?.products.map(product => (
          <Box key={product.productType}>
            <InfluencerAttributeValuePair
              attribute={product.productType}
              value={product.reason}
              variant='simple'
            />
            <Divider orientation="horizontal"/>
          </Box>
        ))
      }
    </InfluencerCard>
  )
}