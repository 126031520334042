import { Typography, Slider, SliderProps } from '@mui/material'
import { SFormControl } from 'components/selects/styled'
import React, { useEffect } from 'react'
import { FieldName, FieldValues, RegisterOptions } from 'react-hook-form'
import { formatNumber } from 'shared/utils'

interface InfluencerSliderProps extends SliderProps {
  isRequired?: boolean
  label: string
  name: string
  value?: [number, number]
  // eslint-disable-next-line no-unused-vars
  setValue(value: [number, number]): void
}

const sliderMarks = [
  0, 100, 1100, 11000, 111100, 1111100, 11111100
];

export const InfluencerSlider = (props: InfluencerSliderProps) => {
  const [localValue, setLocalValue] = React.useState([0, Math.round((props.max || 10000) / 32)]);

  useEffect(() => {
    if (props.value) { 
      setLocalValue([descale(props.value[0]), descale(props.value[1])])
    }
  }, [props.value])

  const validationOptions: RegisterOptions<
    FieldValues,
    FieldName<FieldValues>
    > = {};
  
  if (props.isRequired) {
    validationOptions.required = `${props.label} is required`;
  }

  const scale = (value: number): number => {
    const previousMarkIndex = Math.floor(value / 100);
    const previousMark = sliderMarks[previousMarkIndex];
    const remainder = value % 100;
    if (remainder === 0) {
      return previousMark;
    }
    const nextMark = sliderMarks[previousMarkIndex + 1];
    const increment = (nextMark - previousMark) / 100;
    return remainder * increment + previousMark;
  };

  const descale = (scaledValue: number): number => {
    const previousMarkIndex = sliderMarks.findIndex(mark => mark > scaledValue) - 1
    const previousMark = sliderMarks[previousMarkIndex];
    const nextMarkIndex = previousMarkIndex + 1
    const nextMark = sliderMarks[nextMarkIndex];
    if (nextMark === scaledValue) {
      return nextMarkIndex * 100;
    }
    
    const remainder = scaledValue - previousMark;
    const increment = (nextMark - previousMark)
    return previousMarkIndex * 100 + (remainder / increment * 100)
  };

  const scaleReturnValue = (value: [number, number]): [number, number] => {
    return [scale(value[0]), scale(value[1])]
  };

  const onChange = (value: [number, number]) => {
    setLocalValue(value)
    props.setValue(scaleReturnValue(value))
  }
  
  return (
    <SFormControl
      disabled={props.disabled}
      fullWidth
      variant="outlined"     
    >
      <Typography id="input-slider" variant='body2'>
        { props.label }
      </Typography>
      <Slider
        value={localValue}
        valueLabelFormat={value => formatNumber(value, 1)}
        valueLabelDisplay="auto"
        scale={scale}
        min={0}
        max={Math.round((props.max || 10000) / 32)}
        onChange={(_, value: [number, number]) => onChange(value)}
        sx={{width: 0.95, alignSelf: 'center'}}
      />
    </SFormControl>
  )
}