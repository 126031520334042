import { Dispatch, createContext } from 'react';
import { Influencer } from './influencerContextTypes';

interface InfluencerContextType {
  influencer?: Influencer,
  setInfluencer: Dispatch<Partial<Influencer>>
}

export const InfluencerContext = createContext<InfluencerContextType>({
  setInfluencer: () => {
    // do nothing
  }
});