import React from 'react';
import { useInfluencerProfile } from 'shared/contexts';
import { InfluencerCard } from 'components';

export const InfluencerProfileBio = () => {
  const { influencerProfile } = useInfluencerProfile()

  if (!influencerProfile) {
    return (<></>);
  }

  return (
    <InfluencerCard
      title='Bio'
    >
      { influencerProfile.biography }
    </InfluencerCard>
  )
}