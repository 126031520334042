import React from 'react';
import { InfluencerAttributeValuePair } from 'components';
import { InfluencerPriceRangesForAdTypesResponse } from 'shared/hooks';
import { Box } from '@mui/material';
import { formatMoney } from 'shared/utils';
import { withLoading } from 'shared/hoc';

interface InfluencerProfilePricesContentProps {
  priceRanges?: InfluencerPriceRangesForAdTypesResponse[]
}

const InfluencerProfilePricesContent = (props: InfluencerProfilePricesContentProps) => {
  const { priceRanges } = props

  return (
    <>
      {priceRanges?.map(priceRange => (
        <Box key={priceRange.adType}>
          <InfluencerAttributeValuePair
            attribute={priceRange.adType}
            spaceBetween
            value={`${formatMoney(priceRange.minPrice)} - ${formatMoney(priceRange.maxPrice)}`}
          />
        </Box>
      ))}
    </>
  )
}

export const InfluencerProfilePricesContentWithLoading = withLoading(InfluencerProfilePricesContent)