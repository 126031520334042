import { Paper, Button, Box, Stepper, Step, StepButton, StepLabel, Grid } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { FlexCenter } from 'styles/mixins';
import { InfluencersStepConnector, InfluencersStepIcon } from 'components';

interface StepperRenderedContentProps {
  children?: React.ReactNode;
  nextStepDisabled?: boolean,
  onFinish(): void,
  steps: string[],
}

export const StepperContainer = (props: StepperRenderedContentProps) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const totalSteps = () => {
    return props.steps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    setCompleted({...completed, [activeStep]: true});
    setActiveStep(activeStep + 1);
  };

  const handleFinish = () => {
    props.onFinish();
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
    >
      <Paper className='stepper-paper' elevation={2} sx={{ ...FlexCenter(0.9), backgroundColor: theme.palette.primary.main, zIndex: 1 }} >
        <Box sx={FlexCenter(0.5)}>
          <Stepper activeStep={activeStep} alternativeLabel connector={<InfluencersStepConnector />} sx={{ m: 1 }}>
            {props.steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color="inherit">
                  <StepLabel StepIconComponent={InfluencersStepIcon}>
                    <Box width="10vh" color={index <= activeStep ? theme.palette.grey[50] : theme.palette.grey[500]}>
                      {label}
                    </Box>
                  </StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Paper>
      <Paper className='stepper-content-paper' elevation={2} sx={{ ...FlexCenter(1), marginTop: -5, paddingTop: 10, paddingBottom: 5, paddingX: 20, display: 'block'}} >
        <Box className='stepper-content-box' sx={{ ...FlexCenter(1)}}>
          {React.Children.toArray(props.children)[activeStep]}
        </Box>
        <Box width={1} textAlign='right' mt={3}>
          <Button
            variant="contained"
            color={'secondary'}
            onClick={() => isLastStep() ? handleFinish() : handleNext()}
            disabled={props.nextStepDisabled}
            sx={{}}
          >
            {isLastStep() ? 'Finish' : 'Next'}
          </Button>
        </Box>
      </Paper>
   </Grid>
  )
} 
