import { Dispatch, createContext } from 'react';
import { InfluencerProfile } from './influencerProfileContextTypes';

interface InfluencerProfileContextType {
  influencerProfile?: InfluencerProfile,
  setInfluencerProfile: Dispatch<Partial<InfluencerProfile | undefined>>
}

export const InfluencerProfileContext = createContext<InfluencerProfileContextType>({
  setInfluencerProfile: () => {
    // do nothing
  }
});