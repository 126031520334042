import { Box, Divider, Paper, Stack, Typography, useTheme } from '@mui/material'
import { Variant as TypographyVariant } from '@mui/material/styles/createTypography';
import { InfluencerAvatar } from 'components/avatars';
import React from 'react'
import { withLoading } from 'shared/hoc';
import countryList from 'react-select-country-list';

type Variant = 'main' | 'subcard'
// eslint-disable-next-line no-unused-vars
type TitleVariant = {[key in Variant]: TypographyVariant}
// eslint-disable-next-line no-unused-vars
type Padding = {[key in Variant]: string}

interface InfluencerCardProps {
  children?: React.ReactNode;
  country?: string;
  hideDivider?: boolean;
  title?: string;
  titleHelper?: string | number;
  titleImageSrc?: string;
  subtitleContent?: React.ReactNode;
  variant?: Variant;
}

const titleVariant: TitleVariant = {
  main: 'h5',
  subcard: 'subtitle1',
}

const padding: Padding = {
  main: '12px 16px',
  subcard: '6px 8px',
}

const InfluencerCard = (props: InfluencerCardProps) => {
  const { children, country, hideDivider, title, titleHelper, titleImageSrc, subtitleContent, variant='main' } = props
  const theme = useTheme()
  const countryCode = country ? countryList().getValue(country) : undefined

  return (
    <Paper
      variant="outlined"
      sx={{
        padding: padding[variant],
        borderRadius: 2,
        borderColor: theme.palette.grey[400]
      }}
    >
      <Stack alignItems='center' spacing={1}>
        {titleImageSrc && 
          <InfluencerAvatar
            size='veryBig'
            src={titleImageSrc}
          />
        }
        {title && 
          <Typography variant={titleVariant[variant]}>{title}</Typography>
        }
        {countryCode &&
          <InfluencerAvatar
            size='small'
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`}
            tooltip={country}
          />
        }
        {titleHelper && 
          <Typography variant='caption'>{titleHelper}</Typography>
        }
        {subtitleContent}
        {!hideDivider && children && <Divider flexItem />}
        <Box paddingTop={1} paddingX={1} width={1}>
          {children}
        </Box>
      </Stack>
    </Paper>
  )
}

const InfluencerCardWithLoading = withLoading(InfluencerCard)

export { InfluencerCard, InfluencerCardWithLoading };