import { Container } from '@mui/material';
import { InfluencerCard } from 'components';
import React from 'react';

export const CookieDeclarationPage = () => {  
  return (
    <Container maxWidth='sm'>
      <InfluencerCard
        hideDivider
      >
        <script id="CookieDeclaration" src="https://consent.cookiebot.com/bb45a72d-4474-460e-8105-befb46c41470/cd.js" type="text/javascript" async></script>
      </InfluencerCard>
    </Container>
  );
}