import React from 'react';
import { Avatar, Tooltip, useTheme } from '@mui/material';

type Size = 'verySmall' | 'small' | 'medium' | 'big' | 'veryBig'
// eslint-disable-next-line no-unused-vars
type SizePX = {[key in Size]: number}

interface InfluencerAvatarProps {
  color?: 'primary' | 'secondary'
  size: Size
  src?: string
  tooltip?: string
  alt?: string
}

const sizePx: SizePX = {
  verySmall: 20,
  small: 30,
  medium: 45,
  big: 70,
  veryBig: 140,
}

export const InfluencerAvatar = (props: InfluencerAvatarProps & React.PropsWithChildren) => {
  const { color = 'primary', size, src, tooltip, children, alt } = props
  const theme = useTheme()

  return (
    <Tooltip title={tooltip}>
      <Avatar
        sx={{
          backgroundColor: theme.palette[color].main,
          borderColor: theme.palette.grey[400],
          borderStyle: 'solid',
          borderWidth: 1,
          color: theme.palette.common.white,
          display: 'inline-flex',
          height: sizePx[size],
          width: sizePx[size],
        }}
        src={src}
        alt={alt || tooltip}
      >
        { children }
      </Avatar>
    </Tooltip>
  )
}