import { Container, Divider, Stack, Typography} from '@mui/material';
import { InfluencerCard } from 'components';
import React from 'react';

export const AboutUsPage = () => {
   
  return (
    <Container maxWidth='sm'>
      <InfluencerCard
        hideDivider
      >
        <Stack spacing={2} p={2}>
          <Typography variant='h5'>About Us</Typography>
          <Typography variant='body1'>
            Welcome to our web platform for influencer discovery and advertising opportunities. We understand the power of influencers in today&apos;s digital landscape, and we are dedicated to providing you with a seamless experience to connect with the right influencers for your brand.
          </Typography>
          <Divider/>
          <Typography variant='h5'>Our Mission</Typography>
          <Typography variant='body1'>
            At our core, we strive to simplify the process of influencer marketing by offering a comprehensive database of influencers from various industries and niches. Our mission is to empower businesses, brands, and individuals to harness the potential of influencer advertising, enabling them to reach their target audience effectively.
          </Typography>
          <Divider/>
          <Typography variant='h5'>What We Offer</Typography>
          <Typography variant='subtitle1'>Search and Discover</Typography>
          <Typography variant='body1'>
            Our user-friendly interface allows you to easily search and discover influencers based on your specific requirements. Whether you have a budget in mind or need influencers who specialize in specific topics, our search filters will streamline the process, ensuring you find the perfect match for your advertising campaign.
          </Typography>
          <Typography variant='subtitle1'>Influencer Profiles</Typography>
          <Typography variant='body1'>
            Each influencer on our platform has a detailed profile that provides you with valuable insights. You can explore their previous collaborations, view their content, and assess their engagement rates to make informed decisions. Furthermore, influencers themselves have the opportunity to showcase their rates, ensuring transparent and accurate pricing information.
          </Typography>
          <Typography variant='subtitle1'>Pricing</Typography>
          <Typography variant='body1'>
            We believe in transparency and accuracy, which is why we take great care to ensure the pricing information on our platform is reliable. Influencers can directly provide their rates, and we also incorporate feedback and pricing details from those who have previously worked with them. This helps you make well-informed decisions and ensures you can plan your marketing budget effectively.
          </Typography>
          <Typography variant='subtitle1'>Start Your Influencer Journey Today</Typography>
          <Typography variant='body1'>
            Whether you are a brand looking to increase your online presence or an influencer seeking exciting collaborations, our web application is your gateway to success. Begin your influencer journey today and unlock a world of possibilities.
          </Typography>
        </Stack>
      </InfluencerCard>
    </Container>
  );
}