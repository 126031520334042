import { IconButton, InputAdornment, SelectProps } from '@mui/material';
import React from 'react';
import { FieldError, FieldName, FieldValues, Merge, RegisterOptions, UseFormRegister } from 'react-hook-form';
import { InfluencerCardPicker } from 'components/dialogs';
import { InfluencerTextField } from 'components/inputs/InfluencerTextField';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

type InfluencerDialogSelectProps = SelectProps & {
  dialogCardDescriptions: string[];
  dialogCardHeaders: string[];
  dialogCardKeys: string[];
  dialogTitle: string;
  errors?: Merge<FieldError, (FieldError | undefined)[]>;
  isRequired?: boolean;
  label: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: UseFormRegister<any>;
  // eslint-disable-next-line no-unused-vars
  setValue(value: string): void;
}

export const InfluencerDialogSelect = (props: InfluencerDialogSelectProps) => {
  const [selectedKey, setSelectedKey] = React.useState<string>()
  const [adTypePickerOpen, setAdTypePickerOpen] = React.useState(false);

  const openAdTypePicker = () => {
    setAdTypePickerOpen(true)
  }

  const closeAdTypePicker = (key: string) => {
    setSelectedKey(key)
    props.setValue(key)
    setAdTypePickerOpen(false)
  }

  const getSelectedValue = (): string => {
    if (!selectedKey) return '';

    const dialogDardItemIndex = props.dialogCardKeys.indexOf(selectedKey);
    return `${props.dialogCardHeaders[dialogDardItemIndex]} - ${props.dialogCardDescriptions[dialogDardItemIndex]}`
  }

  const validationOptions: RegisterOptions<
    FieldValues,
    FieldName<FieldValues>
  > = {};
  
  if (props.isRequired) {
    validationOptions.required = `${props.label} is required`;
    props.register && props.register(props.name, validationOptions);
  }

  return (
    <>
      <InfluencerTextField
        errors={props.errors}
        isRequired={props.isRequired}
        label={props.label}
        onClick={openAdTypePicker}
        sx={{ input: { cursor: 'pointer' } }}
        value={getSelectedValue()}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton color="primary">
                <ArrowDropDownIcon />
              </IconButton>
            </InputAdornment>
          ),
          readOnly: true
        }}
      />
      <InfluencerCardPicker
        cardDescriptions={props.dialogCardDescriptions}
        cardHeaders={props.dialogCardHeaders}
        cardKeys={props.dialogCardKeys}
        onClose={closeAdTypePicker}
        open={adTypePickerOpen}
        title={props.dialogTitle}
      />
    </>
  )
}