import React, { useReducer, ReactNode } from 'react';
import { InfluencerProfileContext } from './influencerProfileContext';
import { InfluencerProfile } from './influencerProfileContextTypes';
import { reducer } from '../utils';

interface InfluencerProviderProps {
  children?: ReactNode,
  initialState?: InfluencerProfile
}

export const InfluencerProfileProvider = (props: InfluencerProviderProps) => {
  const [influencerProfile, setInfluencerProfile] = useReducer(reducer<InfluencerProfile | undefined>, props.initialState);

  return (
    <InfluencerProfileContext.Provider value={{ influencerProfile, setInfluencerProfile }}>
      {props.children}
    </InfluencerProfileContext.Provider>
  );
}
