import React from 'react';
import { Typography, Button, ButtonProps } from '@mui/material';


interface InfluencerTopMenuButton {
  disabled?: boolean
  label: string,
  Icon?: React.ElementType
}

const InfluencerTopMenuButton = (props: InfluencerTopMenuButton & ButtonProps) => (
  <Button
    autoCapitalize='o'
    variant="text"
    color="inherit"
    sx={{paddingX: 3}}
    {...props}
  >
    {props.Icon && <props.Icon sx={{marginRight: 1}} />}
    <Typography variant='subtitle2'>
      {props.label}
    </Typography>
  </Button>
)

export { InfluencerTopMenuButton }