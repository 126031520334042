import { CircularProgress } from '@mui/material';
import React, { PropsWithChildren, FC } from 'react';

interface WithLoadingProps {
  loading: boolean;
}

// TODO full screen center loader
export const withLoading = <T,>(Component: FC<T>) => {
  const ComponentWithLoading = (props: T & WithLoadingProps) => {
    return props.loading ? <CircularProgress /> : <Component {...(props as PropsWithChildren<T>)} />
    
  }
  ComponentWithLoading.displayName = 'ComponentWithLoading'
  return ComponentWithLoading
}