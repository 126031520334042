import { useGet } from 'shared/utils/api';

const BASE_API = `${process.env.REACT_APP_BASE_API}/ad-types`;

interface GetAdTypesResponse {
  _id: string,
  name: string,
  description: string,
  platformType: string,
};

const useGetAdTypes = () => useGet<undefined, GetAdTypesResponse[]>(`${BASE_API}/?platform_type=Instagram`);

export { useGetAdTypes, GetAdTypesResponse };