import { Grid, Stack } from '@mui/material';
import React from 'react';
import { withLoading } from 'shared/hoc';
import { InfluencerProfileGeneralInfo } from '../influencerProfileGeneralInfo';
import { InfluencerProfileBio } from '../influencerProfileBio';
import { InfluencerProfileStats } from '../influencerProfileStats';
import { InfluencerProfileFeedback } from '../influencerProfileFeedback';
import { InfluencerProfilePrices } from '../influencerProfilePrices';
import { InfluencerProfileContact } from '../influencerProfileContact';
import { InfluencerProfileInsights } from '../influencerProfileInsights';
import { InfluencerProfileAbout } from '../influencerProfileAbout';
import { InfluencerProfilePromotedBrands } from '../influencerProfilePromotedBrands';

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.subtitle1,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));

// Continue: create subcomponents to display info, useInfluencerProfile can get various info
const InfluencerProfileContent = () => {
  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
    >
      <Grid container item xs={12} sm={4} md={3} spacing={1}>
        <Grid item xs={12}> 
          <InfluencerProfileGeneralInfo />
        </Grid>
        <Grid item xs={12}>
          <InfluencerProfileAbout />
        </Grid>
        <Grid item xs={12}> 
          <InfluencerProfileBio />
        </Grid>
      </Grid>
      <Grid container item xs={12} sm={8} md={9} spacing={1}>
        <Grid item xs={12} md={4}> 
          <InfluencerProfileStats />
        </Grid>
        <Grid item xs={12} md={5}> 
          <Stack spacing={1}>
            <InfluencerProfilePrices />
            <InfluencerProfileContact />
          </Stack>
        </Grid>
        <Grid item xs={12} md={3}> 
          <InfluencerProfilePromotedBrands />
        </Grid>
        <Grid item xs={12}> 
          <InfluencerProfileFeedback />
        </Grid>
        <Grid item xs={12}> 
          <InfluencerProfileInsights />
        </Grid>
        {/* <Grid item xs={12}> 
          <Item sx={{ paddingY: 8 }}>Related Influencers</Item>
        </Grid> */}
      </Grid>
    </Grid>
  );
}

export const InfluencerProfileContentWithLoading = withLoading(InfluencerProfileContent);