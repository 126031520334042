import React from 'react';
import { InfluencerDialog } from 'components';
import { InfluencerProfileFeedbackForm, InfluencerProfileFeedbackFormInputs } from '../influencerProfileFeedbackForm';
import { FormProvider, useForm } from 'react-hook-form';
import { useGetAdTypes, usePostInfluencerFeedback } from 'shared/hooks';
import { useInfluencerProfile } from 'shared/contexts';

interface InfluencerProfileFeedbackDialogProps {
  onClose(): void;
  onSuccessfulSubmit(): void;
  open: boolean;
}

export const InfluencerProfileFeedbackDialog = (props: InfluencerProfileFeedbackDialogProps) => {
  const { onClose, onSuccessfulSubmit, open } = props

  const { influencerProfile } = useInfluencerProfile()

  if (!influencerProfile) {
    return (<></>);
  }

  const { responseData: adTypes, loading: adTypeLoading} = useGetAdTypes();
  const { apiCall: postInfluencerFeedback, loading: postInfluencerFeedbackLoading } = usePostInfluencerFeedback(influencerProfile._id);

  const feedbackFormMethods = useForm<InfluencerProfileFeedbackFormInputs>({ mode: 'all' });
  
  const handleFeedbackSubmit = (formValues: InfluencerProfileFeedbackFormInputs) => {
    const feedbackEntity = {
      prices: formValues.prices || [],
      comment: formValues.comment,
      rating: formValues.rating
    }

    postInfluencerFeedback(feedbackEntity, 'Feedback saved!')
      .then(() => {
        feedbackFormMethods.reset();
        onSuccessfulSubmit()
      });
  }

  const handleDialogClose = () => {
    feedbackFormMethods.reset();
    onClose()
  }

  return (
    <InfluencerDialog onClose={handleDialogClose} open={open} title={`Worked with ${influencerProfile.username} before? Leave a feedback!`}>
      <FormProvider {...feedbackFormMethods}>
        <InfluencerProfileFeedbackForm
          adTypes={adTypes || []}
          loading={adTypeLoading || postInfluencerFeedbackLoading}
          onSubmit={handleFeedbackSubmit}
        />
      </FormProvider>
    </InfluencerDialog>
  )
}