import { InfluencerProfileInsights } from 'shared/contexts/influencerProfileContext/influencerProfileContextTypes';
import { useGet, usePost, usePut } from 'shared/utils/api';

const BASE_API = `${process.env.REACT_APP_BASE_API}/influencers`;

interface GetInfluencerResponse {
  _id: string;
  linkToProfile?: string;
  username: string;
  name: string;
  platformType: string;
  linkToProfilePicture?: string;
  website?: string;
  topics: string[];
  minPrice?: number;
  maxPrice?: number;
  avgRating?: number;
  biography?: string;
  followsCount: number;
  followerCount: number;
  mediaCount: number;
  engagementRate: number;
  mediaLikesCountAverage: number;
  mediaCommentsCountAverage: number;
  mediaVideoViewsCountAverage: number;
  country: string;
  insights?: InfluencerProfileInsights;
  contactEmail?: string | null;
  gender?: string;
  ageMin?: number;
  ageMax?: number;
  category?: string;
  promotedBrands: string[];
};

interface GetInfluencersSummariesRequest {
  skip: number;
  limit: number;
  tags?: string[];
  topics?: string[];
  minPrice?: number;
  maxPrice?: number;
  name?: string;
  minFollowers?: number;
  maxFollowers?: number;
  followersRangeIntervalKey?: string;
  hideWithoutPromotions?: boolean;
  includeBusinessProfiles?: boolean;
};

interface GetInfluencersSummariesResponse {
  _id: string;
  username: string;
  name?: string;
  platformType: string;
  linkToProfile: string;
  linkToProfilePicture?: string;
  minPrice?: number;
  maxPrice?: number;
  avgRating?: number;
  followerCount: number;
  followsCount: number;
};

interface PostInfluencerRequest {
  username: string,
  platformType: string,
};

interface PostInfluencerResponse {
  id: string,
  username: string,
  platformType: string,
  linkToProfile: string,
  linkToProfilePicture?: string,
};

interface PutInfluencerRequest {
  topicIds: string[]
}

const useGetInfluencer = (influencerId: string) => useGet<undefined, GetInfluencerResponse>(`${BASE_API}/${influencerId}/`);

const useGetInfluencersSummaries = () => useGet<GetInfluencersSummariesRequest, GetInfluencersSummariesResponse[]>(`${BASE_API}/summaries/`, undefined, undefined, true);

const usePostInfluencer = () => usePost<PostInfluencerRequest, PostInfluencerResponse>(`${BASE_API}/`);

const usePostInfluencerAsync = () => usePost<PostInfluencerRequest, PostInfluencerResponse>(`${BASE_API}/async/`);

const usePutInfluencer = (influencerId: string) => usePut<PutInfluencerRequest, undefined>(`${BASE_API}/${influencerId}/`);

export {
  useGetInfluencer,
  useGetInfluencersSummaries,
  usePostInfluencer,
  usePostInfluencerAsync,
  usePutInfluencer,
  PostInfluencerResponse,
  GetInfluencersSummariesRequest, 
  GetInfluencersSummariesResponse
};