import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Card, CardContent, Grid } from '@mui/material';
import { InfluencerDialog } from './InfluencerDialog';

interface InfluencerCardPickerProps {
  cardDescriptions: string[];
  cardHeaders: string[];
  cardKeys: string[];
  // eslint-disable-next-line no-unused-vars
  onClose(value?: string): void;
  open: boolean;
  title: string;
}

export const InfluencerCardPicker = (props: InfluencerCardPickerProps) => {
  const onCardItemClick = (value: string) => {
    props.onClose(value);
  };

  // TODO ability to add custom type

  return (
    <InfluencerDialog onClose={props.onClose} open={props.open} title={props.title}>
      <Grid container spacing={2} padding={2}>
        {props.cardHeaders.map((cardHeader, index) => (
          <Grid item md={4} sm={6} xs={12} textAlign='center' key={`card-picker-dialog-item-${props.cardKeys[index]}`}>
            <Card onClick={() => onCardItemClick(props.cardKeys[index])} sx={{cursor: 'pointer'}}>
              <CardContent>
                <Typography gutterBottom variant="h5">
                  { cardHeader }
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  { props.cardDescriptions[index] }
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </InfluencerDialog>
  );
}