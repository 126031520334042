import React from 'react';
import { Grid } from '@mui/material';
import { InfluencerSubmitButton, InfluencerTextField } from 'components';
import { FormProps } from 'shared/utils';
import { useFormContext } from 'react-hook-form';

export interface InfluencerAsyncCreationFormInputs {
  username: string;
}

export const InfluencerAsyncCreationForm = (
  {onSubmit, loading, submitDisabled}: FormProps<InfluencerAsyncCreationFormInputs>
) => {
  
  const {
    register,
    formState: { isValid },
    handleSubmit,
  } = useFormContext<InfluencerAsyncCreationFormInputs>();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container >
        <Grid item xs={12} lg={4} />
        <Grid item xs={12} lg={4} textAlign='center'>
          <InfluencerTextField
            label='Username'
            name='username'
            register={register}
          />
        </Grid>
        <Grid item xs={12} lg={4} />
        <Grid item xs={12} lg={4} />
        <Grid item xs={12} lg={4} alignSelf='center'>
          <InfluencerSubmitButton
            label='Submit'
            disabled={loading || submitDisabled || !isValid}
            loading={loading}
          />
        </Grid>
      </Grid>
    </form>
  );
}
