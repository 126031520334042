import React from 'react';
// Handle console logs
import 'shared/utils/dropConsole';
// Styles
import 'fontsource-roboto';
// ROUTER
import { ApiInterceptors } from 'shared/utils';
import { BrowserRouter } from 'react-router-dom';
import { RouterConfig, fullWidthRoutes } from 'navigation/RouterConfig';
// MUI Theme
import { TopMenu, ToastContainer } from 'components';
import ThemeCustomization from 'styles';
import './App.scss';
// import { ProvideAuth } from "navigation/Auth/ProvideAuth";
// Redux
import { Box, Container } from '@mui/material';
import { UserProvider } from 'shared/contexts';
import { InfluencerFooter } from 'components/footer/influencerFooter';


function App() {
  const isFullWidth = fullWidthRoutes.includes(window.location.pathname);

  return (
    <ThemeCustomization>
      <BrowserRouter>
        <UserProvider>
          <ApiInterceptors>
            {isFullWidth ? (
              <Box>
                <TopMenu/>
                <RouterConfig/>
                <ToastContainer/>
              </Box>
            ) : (
              <Container className='main-container' maxWidth='xl'>
                <TopMenu/>
                <RouterConfig/>
                <ToastContainer/>
              </Container>  
            )}
          </ApiInterceptors>
        </UserProvider>
        <InfluencerFooter />
      </BrowserRouter>
    </ThemeCustomization>
  );
}

export default App;
