import React from 'react';
import { IconButton, ImageListItem, ImageListItemBar } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './influencerImageListItem.scss';

interface InfluencerImageListItem {
  key: string,
  onClick?(): void,
  selected?: boolean,
  src: string,
  title: string
}

const InfluencerImageListItem = (props: InfluencerImageListItem) => (
  <ImageListItem key={props.key}>
    <LazyLoadImage
      src={props.src}
      onClick={props.onClick}
      className={`image ${props.selected && 'image--selected'}`}
    />
    <ImageListItemBar
      sx={{
        background:
          'linear-gradient(to top, rgba(0,0,0,0.7) 0%, ' +
          'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
      }}
      
      title={props.title}
      position="bottom"
      actionIcon={
        <IconButton
          aria-label={props.title}
        />
      }
      actionPosition="right"
    />
  </ImageListItem>
)

export { InfluencerImageListItem }