import React, { useEffect } from 'react';
import { useUser } from 'shared/contexts';
import { genericErrorHandler } from './globalErrorHandler';
import axios from 'axios';

const ApiInterceptors = (props: React.PropsWithChildren) => {
  const { children } = props
  const { setUser } = useUser()

  useEffect(
    () => {
      axios.interceptors.response.clear();
      axios.interceptors.response.use(null, (response) =>
        genericErrorHandler(() => setUser({}), response?.response)
      );
    }, [])

  return (
    <>
      {children}
    </>
  )
};

export { ApiInterceptors };
