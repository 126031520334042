import './influencerAutocomplete.scss';
import React from 'react'
import { Autocomplete, Checkbox, FormHelperText, TextField } from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Merge, FieldError, RegisterOptions, FieldName, FieldValues, Controller, Control } from 'react-hook-form';
import { SFormControl } from 'components/selects/styled';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface InfluencerAutocompleteProps {
  control: Control
  disabled?: boolean
  errors?: Merge<FieldError, (FieldError | undefined)[]>
  isRequired?: boolean
  label: string
  name: string
  options: string[]
}

export const InfluencerAutocomplete = (props: InfluencerAutocompleteProps) => {
  const validationOptions: RegisterOptions<
    FieldValues,
    FieldName<FieldValues>
  > = {};
  
  if (props.isRequired) {
    validationOptions.required = `${props.label} is required`;
  }
  
  return (
    <SFormControl
      disabled={props.disabled}
      fullWidth
      variant="outlined"     
    >
      <Controller
        control={props.control}
        defaultValue={[]}
        name={props.name}
        rules={validationOptions}
        render={({ field }) => (
          <Autocomplete
            {...field}
            multiple
            options={props.options}
            disableCloseOnSelect
            onChange={(_, value: string[]) => field.onChange(value)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label={props.label} />
            )}
          />
        )}
      />
      <FormHelperText id="component-error-text">{props.errors?.message}</FormHelperText>
    </SFormControl>
  )
}