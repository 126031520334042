import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box } from '@mui/material';

export type InfluencerSlickSliderProps = {
  children?: React.ReactNode;
  slidesToShow?: number
}

export const InfluencerSlickSlider = ({ children, slidesToShow }: InfluencerSlickSliderProps) => {
  const settings = {
    centerMode: false,
    infinite: true,
    centerPadding: '60px',
    slidesToShow: slidesToShow,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 1700,
    vertical: true
  };
  return (
    <Box className="slider-container">
      <Slider {...settings}>
        { children }
      </Slider>
    </Box>
  );
}