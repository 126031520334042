import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { InfluencerPricesForm, InfluencerPricesFormInputs } from '../influencerPricesForm';
import { FormProvider, useForm } from 'react-hook-form';
import { usePostInfluencerFeedbacks } from 'shared/hooks';
import { useInfluencer } from 'shared/contexts';

interface InfluencerPricesProps {
  onSuccessfulSubmit(): void
  onFailureSubmit(): void
}

export const InfluencerPrices = (props: InfluencerPricesProps) => {
  const formMethods = useForm<InfluencerPricesFormInputs>({mode: 'all'});
  const { influencer } = useInfluencer(); 
  // TODO not a clean way to pass null if influencer is not found
  const { apiCall: postInfluencerPrices, loading: postInfluencerPricesLoading } = usePostInfluencerFeedbacks(influencer?._id || '');

  useEffect(() => {
    if (formMethods.formState.isDirty) {
      if (formMethods.getValues().prices.length === 0) props.onSuccessfulSubmit()
      else if (formMethods.getValues().prices.length === 1) props.onFailureSubmit()
    }
  }, [formMethods.getValues().prices, formMethods.formState.isDirty, formMethods.formState.isValid])


  const onSubmit = (formValues: InfluencerPricesFormInputs) =>
    postInfluencerPrices([{prices: formValues.prices}], 'Prices saved!').then(() => {
      props.onSuccessfulSubmit();
    }).catch(() => {
      props.onFailureSubmit();
      return Promise.reject();
    });

  return (
    <Box width={1}>
      <FormProvider {...formMethods}>
        <InfluencerPricesForm
          onSubmit={onSubmit}
          loading={postInfluencerPricesLoading}
        />
      </FormProvider>
    </Box>
  )
}