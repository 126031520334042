import React, { useEffect }  from 'react';
import { useInfluencerProfile } from 'shared/contexts';
import { useGetInfluencer } from 'shared/hooks';
import { InfluencerProfileContentWithLoading } from '../influencerProfileContent';

interface InfluencerProfileProps {
  influencerId: string
}

export const InfluencerProfile = (props: InfluencerProfileProps) => {
  const { influencerId } = props;
  const { responseData: influencerData, loading: influencerLoading } = useGetInfluencer(influencerId)
  const { setInfluencerProfile } = useInfluencerProfile()

  useEffect(() => {
    if(influencerData) setInfluencerProfile(influencerData)
  }, [influencerData])
  
  return (
    <InfluencerProfileContentWithLoading loading={influencerLoading} />
  )
}