import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { usePutInfluencer } from 'shared/hooks';
import { InfluencerTopicsForm, InfluencerTopicsFormInputs } from '../influencerTopicsForm';
import { FormProvider, useForm } from 'react-hook-form';
import { useInfluencer } from 'shared/contexts';

interface InfluencerTopicsProps {
  onFailureSubmit(): void
  onInit(): void
  onSuccessfulSubmit(): void,
}

export const InfluencerTopics = (props: InfluencerTopicsProps) => {
  const { influencer } = useInfluencer(); 
  const { loading: putInfluencerLoading, apiCall } = usePutInfluencer(influencer?._id || '');
  const formMethods = useForm<InfluencerTopicsFormInputs>({ mode: 'all' });
  
  useEffect(props.onInit, []);
  
  const onSubmit = (formValues: InfluencerTopicsFormInputs) =>
    apiCall({topicIds: formValues.topics}, 'Topics updated!').then(() => {
      props.onSuccessfulSubmit();
    });

  return (
    <Box width={1}>
      <FormProvider {...formMethods}>
        <InfluencerTopicsForm
          loading={putInfluencerLoading}
          onSubmit={onSubmit}
          />
      </FormProvider>
    </Box>
  )
}