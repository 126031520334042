import { Autocomplete, Chip, TextField,  } from '@mui/material';
import React, { useState } from 'react';
import { Control, Controller, FieldError, FieldName, FieldValues, Merge, RegisterOptions, ValidationRule } from 'react-hook-form';
import { SFormControl } from 'components/selects/styled';

export interface InfluencerAutocompleteWithTagsProps {
  name: string
  errors?: Merge<FieldError, (FieldError | undefined)[]>
  label: string
  disabled?: boolean
  options: string[]
  isRequired?: boolean
  minLength?: ValidationRule<number>
  maxLength?: ValidationRule<number>
  min?: ValidationRule<number>
  max?: ValidationRule<number>
  control: Control
}

export const InfluencerAutocompleteWithTags = (props: InfluencerAutocompleteWithTagsProps) => {
  const [inputValue, setInputValue] = useState<string | undefined>();

  const validationOptions: RegisterOptions<
    FieldValues,
    FieldName<FieldValues>
    > = {};
  
  if (props.isRequired) {
    validationOptions.required = `${props.label} is required`;
  }

  return (
    <SFormControl
      disabled={props.disabled}
      fullWidth
      variant="outlined"     
    >
      <Controller
        control={props.control}
        defaultValue={[]}
        name={props.name}
        rules={validationOptions}
        render={({ field }) => (
          <Autocomplete
            {...field}
            multiple
            freeSolo={field.value.length >= 3 ? false : true}
            onInputChange={(_, value) => setInputValue(value)}
            autoSelect
            options={field.value.length < 3 ? inputValue ? [inputValue, ...props.options] : props.options : []}
            disableCloseOnSelect
            onChange={(_, value: string[]) => field.onChange(value)}
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                  <Chip variant="outlined" label={option} key={key} {...tagProps} />
                );
              })
            }
            renderInput={(params) => (
              <TextField {...params} label={props.label} />
            )}
          />
        )}
      />
    </SFormControl>
  )
}