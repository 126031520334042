import { Dispatch, createContext } from 'react';
import { User } from './userContextTypes';

interface UserContextType {
  user: User,
  setUser: Dispatch<Partial<User>>
}

export const UserContext = createContext<UserContextType>({
  user: {},
  setUser: () => {
    // do nothing
  }
});