import * as React from 'react';
import { styled } from '@mui/material/styles';
import Rating, { IconContainerProps } from '@mui/material/Rating';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { Control, Controller, FieldError, FieldName, FieldValues, Merge, RegisterOptions } from 'react-hook-form';
import { SyntheticEvent } from 'react';
import { Box, FormHelperText, Typography } from '@mui/material';
import { SFormControl } from 'components/selects/styled';

const StyledRating = styled(Rating)(({ theme }) => ({
  fontSize: '2.5rem',
  paddingTop: 5,
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': { color: theme.palette.action.disabled },
  '& .MuiRating-icon': { width: '3rem' }
}));

const ratingIcons: {
  [index: string]: {
    icon: React.ReactElement;
    label: string;
  };
} = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" fontSize='inherit'/>,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" fontSize='inherit'/>,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" fontSize='inherit'/>,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" fontSize='inherit'/>,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" fontSize='inherit'/>,
    label: 'Very Satisfied',
  },
};

const IconContainer = (props: IconContainerProps) => {
  const { value, ...other } = props;
  return <span {...other}>{ratingIcons[value].icon}</span>;
}

interface InfluencerRatingProps {
  control?: Control
  disabled?: boolean
  errors?: Merge<FieldError, (FieldError | undefined)[]>
  isRequired?: boolean
  label?: string
  name?: string
  readOnly?: boolean
  value?: number
}

export const InfluencerRating = (props: InfluencerRatingProps) => {
  const validationOptions: RegisterOptions<
    FieldValues,
    FieldName<FieldValues>
    > = {};
  
  if (props.isRequired) {
    validationOptions.required = 'Rating is required';
  }

  validationOptions.valueAsNumber = true

  return (
    <SFormControl fullWidth>
      <Box width={1} border={props.errors?.message ? 1 : 0} borderColor="error.main" borderRadius={1} textAlign='center'>
        <Typography component="legend" >{ props.label }</Typography>
        {props.control && props.name
          ? (
            <Controller
              name={props.name}
              control={props.control}
              defaultValue={null}
              rules={validationOptions}
              render={({field}) => 
                <StyledRating
                  disabled={props.disabled}
                  IconContainerComponent={IconContainer}
                  getLabelText={(valueNumber: number) => ratingIcons[valueNumber]?.label || ''}
                  highlightSelectedOnly
                  readOnly={props.readOnly}
                  name={field.name}
                  onBlur={field.onBlur}
                  onChange={(_event: SyntheticEvent<Element, Event>, value: number | null) => field.onChange(value)}
                  ref={field.ref}
                  value={field.value}
                />
              }
            />
          ) : (
            <StyledRating
              disabled={props.disabled}
              name={props.name}
              IconContainerComponent={IconContainer}
              getLabelText={(valueNumber: number) => ratingIcons[valueNumber]?.label || ''}
              highlightSelectedOnly
              readOnly={props.readOnly}
              value={props.value}
            />
          )
        }
      </Box>
      <FormHelperText error>{props.errors?.message || ' '}</FormHelperText>
    </SFormControl>
  )
}