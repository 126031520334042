import React from 'react';
import { Box, Typography } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import LogoutOutlined from '@mui/icons-material/LogoutOutlined';
import { useUser } from 'shared/contexts';
import { InfluencerTooltipIcon } from 'components/buttons';
import { useAuth } from 'shared/hooks';

export const TopMenuUserBox = () => {
  const { loading: authLoading, googleLogin, logOut } = useAuth();
  const { user } = useUser();

  return (
    <>
      {user.name
        ? (
          <Box display='flex' alignItems='center'>
            <Typography variant='subtitle2'>{user.name}</Typography>
            <InfluencerTooltipIcon
              disabled={authLoading}
              iconComponent={<LogoutOutlined />}
              onClick={logOut}
              tooltipTitle={'Log out'}
            />
          </Box>
        )
        : (
          <>
            <Box>
              <Typography variant='subtitle2'>Login Or Sign Up:</Typography>
            </Box>
            <Box>
              <InfluencerTooltipIcon
                disabled={authLoading}
                iconComponent={<GoogleIcon />}
                onClick={googleLogin}
                tooltipTitle={'Login using Google'}
              />
            </Box>
          </>
        )}
    </>
  )
}