import { Container, Divider, Stack, Typography} from '@mui/material';
import { InfluencerButton, InfluencerCard } from 'components';
import React from 'react';

export const BlogsPage = () => {
   
  return (
    <Container maxWidth='sm'>
      <InfluencerCard
        hideDivider
      >
        <Stack spacing={2} p={2} alignItems='flex-start'>
          <Typography variant='h5' fontWeight={600}>Top 100 Lithuanian Influencers on Instagram</Typography>
          <Typography variant='body1'>
            Explore our list of the top Instagram influencers in Lithuania, ranked by follower count. From fashion icons to travel enthusiasts, find out who&apos;s captivating audiences and setting trends.
          </Typography>
          <InfluencerButton label="Read more >>" href="/blogs/top-100-lithuanian-influencers" variant='text' fullWidth={false} color='primary' sx={{fontWeight: 600}} />
          <Divider flexItem />

          <Typography variant='h5' fontWeight={600}>How to Choose the Right Influencers for a Brand: A Step-by-Step Guide</Typography>
          <Typography variant='body1'>
            Step-by-step guide on how to choose the right influencers for your brand.
          </Typography>
          <InfluencerButton label="Read more >>" href="/blogs/how-to-choose-the-right-influencers" variant='text' fullWidth={false} color='primary' sx={{fontWeight: 600}} />
          <Divider flexItem />

          <Typography variant='h5' fontWeight={600}>The Future of Influencer Marketing: Trends to Watch in 2024</Typography>
          <Typography variant='body1'>
            Discover the top influencer marketing trends for 2024, including the rise of micro-influencers, AI integration and more. 
          </Typography>
          <InfluencerButton label="Read more >>" href="/blogs/trends-to-watch-in-2024" variant='text' fullWidth={false} color='primary' sx={{fontWeight: 600}} />
          <Divider flexItem />
          
          <Typography variant='h5' fontWeight={600}>Measuring the Effectiveness of Influencer Marketing Campaigns: Metrics and Best Practices</Typography>
          <Typography variant='body1'>
            Unlock the true power of influencer marketing! Discover the essential metrics and best practices to measure the effectiveness of your campaigns and maximize your ROI.
          </Typography>
          <InfluencerButton label="Read more >>" href="/blogs/measuring-the-effectiveness-of-influencer-marketing-campaigns" variant='text' fullWidth={false} color='primary' sx={{fontWeight: 600}} />
          <Divider flexItem />
          
          <Typography variant='h5' fontWeight={600}>Micro-Influencers vs. Macro-Influencers: Which Delivers Better ROI?</Typography>
          <Typography variant='body1'>
            Micro-Influencers vs. Macro-Influencers: Discover which strategy maximizes your marketing ROI. Dive into data-driven insights and real-life case studies to make the best choice for your brand.
          </Typography>
          <InfluencerButton label="Read more >>" href="/blogs/micro-influencers-vs-macro-influencers-roi" variant='text' fullWidth={false} color='primary' sx={{fontWeight: 600}} />
          <Divider flexItem />
          
          <Typography variant='h5' fontWeight={600}>The Dark Side of Influencer Marketing: Transparency, Ethics, and Consumer Trust</Typography>
          <Typography variant='body1'>
            The truth behind influencer marketing: explore the ethical challenges of transparency, fake followers, and consumer trust in the digital age.
          </Typography>
          <InfluencerButton label="Read more >>" href="/blogs/the-dark-side-of-influencer-marketing" variant='text' fullWidth={false} color='primary' sx={{fontWeight: 600}} />
          <Divider flexItem />
          
          <Typography variant='h5' fontWeight={600}>The Evolution of Influencer Marketing: From Celebrity Endorsements to Micro-Influencers</Typography>
          <Typography variant='body1'>
            From hollywood stars to social media sensations: discover how influencer marketing has evolved from celebrity endorsements to the power of micro-influencers, reshaping the way brands connect with consumers.
          </Typography>
          <InfluencerButton label="Read more >>" href="/blogs/the-evolution-of-influencer-marketing" variant='text' fullWidth={false} color='primary' sx={{fontWeight: 600}} />
          <Divider flexItem />
          
          <Typography variant='h5' fontWeight={600}>The Psychology Behind Influencer Trust: Why Consumers Follow and Believe Influencers?</Typography>
          <Typography variant='body1'>
            Explore the psychology behind influencer credibility and discover how social proof, parasocial relationships, and authenticity drive our buying decisions. Dive into the science of influencer trust today!
          </Typography>
          <InfluencerButton label="Read more >>" href="/blogs/the-psychology-behind-influencer-trust" variant='text' fullWidth={false} color='primary' sx={{fontWeight: 600}} />
          <Divider flexItem/>
        </Stack>
      </InfluencerCard>
    </Container>
  );
}