import { Button } from '@mui/material';
import React from 'react';

interface InfluencerLinkProps {
  label: string,
  href?: string
}

export const InfluencerLink = (props: InfluencerLinkProps) => (
  <Button
    sx={{textTransform: 'none'}}
    variant="text"
    href={props.href}
    fullWidth
  >
    {props.label}
  </Button>
)