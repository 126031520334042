import { InputAdornment } from '@mui/material';
import React from 'react';
import { InfluencerTextField, InfluencerTextFieldProps } from './InfluencerTextField';

interface InfluencerPriceFieldProps extends InfluencerTextFieldProps {
  symbol?: string;
}

export const InfluencerPriceField = (props: InfluencerPriceFieldProps) => {

  return (
    <InfluencerTextField
      min={1}
      {...props}
      type='number'
      InputProps={{
        startAdornment: <InputAdornment position="start">{props.symbol || '€'}</InputAdornment>,
      }}
    />
  )
}