import React from 'react';
import { InfluencerAsyncCreation } from './features';
import { InfluencerProfileProvider } from 'shared/contexts';
import { Paper } from '@mui/material';

export const InfluencerAsyncCreationPage = () => {
   
  return (
    <InfluencerProfileProvider>
      <Paper elevation={2} sx={{p: 2}}>
        <InfluencerAsyncCreation />
      </Paper>
    </InfluencerProfileProvider>
  );
}