import React, { useState } from 'react';
import { Typography, Button } from '@mui/material';

interface InfluencerLongTextBoxProps {
  text: string;
  maxLength: number;
}

export const InfluencerLongTextBox = ({ text, maxLength }: InfluencerLongTextBoxProps) => {
  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => {
    setShowMore(true);
  };

  const handleShowLess = () => {
    setShowMore(false);
  };

  const truncatedText = `${text.slice(0, maxLength)}...`;
  const isTextShort = text.length < maxLength
  
  return (
    <Typography variant="body2" component="div" whiteSpace="pre-wrap">
      {showMore || isTextShort ? text : truncatedText}
      {isTextShort ? null : 
        <Button
          onClick={showMore ? handleShowLess : handleShowMore}
          variant='text'
        >
          {showMore ? 'Show less' : 'Read more'}
        </Button>
      }
    </Typography>
  );
}