import { ButtonProps, Typography } from '@mui/material';
import React from 'react';
import { SButton } from './styled';
import CircularProgress from '@mui/material/CircularProgress';
import { Variant } from '@mui/material/styles/createTypography';

interface InfluencerButton extends ButtonProps {
  label: string,
  loading?: boolean,
  textVariant?: Variant
}

const InfluencerButton = (props: InfluencerButton) => (
  <SButton
    type='button'
    variant='contained'
    color='secondary'
    fullWidth
    {...props}
  >
    {props.loading
      ? (<CircularProgress size={24} />)
      : (<Typography variant={props.textVariant || 'inherit'}> {props.label} </Typography>)
    }
  </SButton>
)

export { InfluencerButton }