import * as React from 'react';
import DialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { CircularProgress, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface InfluencerDialogTitle extends DialogTitleProps {
  onClose?(): void
}

interface InfluencerDialogProps {
  children?: React.ReactNode;
  loading?: boolean;
  // eslint-disable-next-line no-unused-vars
  onClose?(value?: string): void;
  open: boolean;
  title: string;
}

const InfluencerDialogTitle = (props: InfluencerDialogTitle) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export const InfluencerDialog = (props: InfluencerDialogProps) => {
  const onClose = () => {
    props.onClose?.();
  };

  return (
    <Dialog onClose={onClose} open={props.open} fullWidth>
      <InfluencerDialogTitle onClose={onClose}>{props.title}</InfluencerDialogTitle>
      <DialogContent>
        {
          props.loading
            ? (<CircularProgress />)
            : props.children
        }
      </DialogContent>
    </Dialog>
  );
}
