import React, { useEffect } from 'react';
import { AppBar, Box, Button, IconButton, Toolbar } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { DASHBOARD } from 'navigation/Endpoints';
import { useTheme } from '@mui/material/styles';
import { InfluencerTopMenuButton } from './TopMenuButton';
import MenuIcon from '@mui/icons-material/Menu';
import { InfluencerDrawer } from './InfluencerDrawer';
import { NavigationItem, getUserNavigationItems } from './utils';
import { TopMenuUserBox } from './TopMenuUserBox';
import { useUser } from 'shared/contexts';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const darkBackgroundRoutes: string[] = [
  DASHBOARD
]

// TODO split component to TopBar and TopMenu
export const TopMenu = () => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [navigationItems, setNavigationItems] = React.useState<NavigationItem[]>([]);
  const { user } = useUser();
  const location = useLocation();
  const { palette } = useTheme();

  useEffect(() => setNavigationItems(getUserNavigationItems(user)), [user])

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const isBackgroundDark = (): boolean => {
    return darkBackgroundRoutes.includes(location.pathname)
  }

  const textColor = (): string => {
    return isBackgroundDark()
      ? palette.common.white
      : palette.secondary.main
  }

  const logoSrc = (): string => {
    return isBackgroundDark()
      ? `${process.env.PUBLIC_URL}/logo-light.webp`
      : `${process.env.PUBLIC_URL}/logo-dark.webp`
  }

  return (
    <AppBar position='static' style={{ background: 'transparent', boxShadow: 'none', marginBottom: 10, color: textColor()}} >
      <Toolbar>
        <Box display={{xs: 'flex', sm: 'none'}} width={1}>
          <IconButton
            aria-label="menu"
            color="inherit"
            onClick={handleDrawerOpen}
            sx={{ mr: 2, ...(drawerOpen && { display: 'none' }) }}>
            <MenuIcon />
          </IconButton>
          <InfluencerDrawer
            onClose={handleDrawerClose}
            open={drawerOpen}
          />
        </Box>
        <Box display={{xs: 'none', sm: 'flex'}} width={1} alignItems='center'>
          <Button
            color="inherit"
            href={DASHBOARD}
          >
            <LazyLoadImage
              src={logoSrc()}
              alt='TrendMyAd logo'
              width={150}
              height={68}
            />
          </Button>
          <Box sx={{ flexGrow: 1 }} />
          {navigationItems.map(({ label, endpoint}) => (
            <InfluencerTopMenuButton
              key={label}
              label={label}
              href={endpoint}
            />
          ))}
          <Box sx={{ flexGrow: 1 }} />
          <Box textAlign='center' paddingX={2}>
            <TopMenuUserBox />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  )
};
