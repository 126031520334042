import { Container } from '@mui/material';
import { InfluencerCard } from 'components';
import React from 'react';
import { MuiMarkdown } from 'mui-markdown';
import { useParams } from 'react-router-dom';
import { useGetBlog } from 'shared/hooks';

type RouteParams = {
  blogId: string
}

export const BlogPage = () => {
  const { blogId } = useParams<RouteParams>() as RouteParams

  const { responseData: blog } = useGetBlog(`${blogId}.md`)

  if (!blog) {
    return <></>
  }
   
  return (
    <Container maxWidth='md'>
      <InfluencerCard hideDivider>
        <MuiMarkdown>{blog}</MuiMarkdown>
      </InfluencerCard>
    </Container>
  );
}