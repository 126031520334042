import { Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { InfluencerBasicInfoForm, InfluencerBasicInfoFormInputs } from '../influencerBasicInfoForm';
import { FormProvider, useForm } from 'react-hook-form';
import { usePostInfluencer } from 'shared/hooks';
import { useInfluencer } from 'shared/contexts';
import { InfluencerAvatar } from 'components';

interface InfluencerBasicInfoProps {
  onSuccessfulSubmit(): void
}

export const InfluencerBasicInfo = (props: InfluencerBasicInfoProps) => {
  const formMethods = useForm<InfluencerBasicInfoFormInputs>();
  const { loading, apiCall } = usePostInfluencer();
  const { influencer, setInfluencer } = useInfluencer(); 
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const onSubmit = (formValues: InfluencerBasicInfoFormInputs) =>
    apiCall({
      username: formValues.username,
      platformType: formValues.platform
    }, 'Influencer Created!').then((responseData) => {
      setInfluencer(responseData);
      props.onSuccessfulSubmit();
      setSubmitDisabled(true);
    });

  return (
    <Grid container spacing={3} textAlign='center' justifyContent='center' alignItems='center'>
      <Grid item xs={12}>
        <Typography variant='h6' gutterBottom lineHeight={4}>Let&apos;s start with the basic information</Typography>
        <Typography>Let us know the influencer&apos;s name and social media for advertisements.</Typography>
      </Grid>
      <Grid item xs={12} md={4} textAlign='center'>
        <InfluencerAvatar
          src={influencer?.linkToProfilePicture}
          size='veryBig'
        />
      </Grid>
      <Grid item xs={12} md={4} textAlign='start'>
        <FormProvider {...formMethods}>
          <InfluencerBasicInfoForm
            onSubmit={onSubmit}
            loading={loading}
            submitDisabled={submitDisabled}
          />
        </FormProvider>
      </Grid>
    </Grid>
  )
}