import React, { useState } from 'react';
import { StepperContainer } from './components/stepperContainer';
import { InfluencerBasicInfo, InfluencerTopics } from './features';
import { InfluencerProvider } from 'shared/contexts';
import { InfluencerPrices } from './features/influencerPrices';
import { useNavigate } from 'react-router-dom';
import { HOME } from 'navigation/Endpoints';

const influencerCreationSteps = ['INFLUENCER INFO', 'TOPICS', 'PRICES'];

// Grazinu submitinta influenceri. Toliau implementinti, kada stepsai yra disablinti, o kada ne
export const InfluencerCreation = () => {
  const [nextStepDisabled, setNextStepDisabled] = useState(true);
  const navigate = useNavigate();

  const enableNextStep = () => {
    setNextStepDisabled(false);
  }

  // TODO send error info also
  const disableNextStep = () => {
    setNextStepDisabled(true);
  }

  const handleCreationFinish = () => {
    // TODO redirect to influencer profile page
    navigate(HOME)
  }

  return (
    <InfluencerProvider>
      <StepperContainer nextStepDisabled={nextStepDisabled} onFinish={handleCreationFinish} steps={influencerCreationSteps}>
        <InfluencerBasicInfo onSuccessfulSubmit={enableNextStep} />
        <InfluencerTopics onFailureSubmit={disableNextStep} onInit={disableNextStep} onSuccessfulSubmit={enableNextStep}  />
        <InfluencerPrices onFailureSubmit={disableNextStep} onSuccessfulSubmit={enableNextStep} />
      </StepperContainer>
    </InfluencerProvider>
  )
}