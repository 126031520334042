import React from 'react';
import { GenericError } from './features/GenericError';

const errorMessage = `
Oopsie-doodle! It seems like we've stumbled upon an influencer who's gone undercover or a page that's playing hide-and-seek.
Don't fret, though! We've dispatched our influencer scouts to track it down.
Take a moment to gather your thoughts and give that "back" button a gentle tap.
Alternatively, you can head on over to our dashboard page by clicking on the icon in top left corner to explore the influencer wonderland we've crafted just for you.
`

export const ErrorNotFoundPage = () => { 
  return (
    <GenericError errorMessage={errorMessage} srcImage={`${process.env.PUBLIC_URL}/errors/not-found.png`} />
  )
}