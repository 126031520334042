import React from 'react';
import { useInfluencerProfile } from 'shared/contexts';
import { InfluencerAttributeValuePair, InfluencerCard } from 'components';
import { formatNumber } from 'shared/utils';

export const InfluencerProfileStats = () => {
  const { influencerProfile } = useInfluencerProfile()

  if (!influencerProfile) {
    return (<></>);
  }

  return (
    <InfluencerCard
      hideDivider
      title='Statistics'
    >
      <InfluencerAttributeValuePair
        attribute='Follower Count'
        spaceBetween={true}
        value={formatNumber(influencerProfile.followerCount)}
      />
      <InfluencerAttributeValuePair
        attribute='Follows Count'
        spaceBetween={true}
        value={formatNumber(influencerProfile.followsCount)}
      />
      <InfluencerAttributeValuePair
        attribute='Media Count'
        spaceBetween={true}
        value={formatNumber(influencerProfile.mediaCount)}
      />
      <InfluencerAttributeValuePair
        attribute='Engagement Rate'
        spaceBetween={true}
        value={formatNumber(influencerProfile.engagementRate)}
      />
      <InfluencerAttributeValuePair
        attribute='Likes Average Per Post'
        spaceBetween={true}
        value={formatNumber(influencerProfile.mediaLikesCountAverage)}
      />
      <InfluencerAttributeValuePair
        attribute='Comments Average Per Post'
        spaceBetween={true}
        value={formatNumber(influencerProfile.mediaCommentsCountAverage)}
      />
      <InfluencerAttributeValuePair
        attribute='Views Average Per Post'
        spaceBetween={true}
        value={formatNumber(influencerProfile.mediaVideoViewsCountAverage)}
      />
    </InfluencerCard>
  )
}