import React from 'react';
import { useParams } from 'react-router-dom';
import { InfluencerProfile } from './features';
import { InfluencerProfileProvider } from 'shared/contexts';

type RouteParams = {
  influencerId: string
}

export const InfluencerProfilePage = () => {
  const { influencerId } = useParams<RouteParams>() as RouteParams
   
  return (
    <InfluencerProfileProvider>
      <InfluencerProfile influencerId={influencerId} />
    </InfluencerProfileProvider>
  );
}