import React from 'react';
import { FieldError, Merge, UseFormRegister } from 'react-hook-form';
import { InfluencerTextField } from './InfluencerTextField';

export interface IInfluencerMultilineTextFieldProps {
  disabled?: boolean
  errors?: Merge<FieldError, (FieldError | undefined)[]>
  name: string
  isRequired?: boolean
  label: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: UseFormRegister<any>
}

export const InfluencerMultilineTextField = (props: IInfluencerMultilineTextFieldProps) =>
  <InfluencerTextField
    multiline
    rows={4}
    {...props}
  />