import { Container, Stack, Typography } from '@mui/material';
import React from 'react';

interface GenericErrorProps {
  errorMessage: string,
  srcImage?: string
}

export const GenericError = (props: GenericErrorProps) => { 
  const { errorMessage, srcImage } = props;

  return (
    <Container maxWidth='sm'>
      <Stack
        alignItems='center'
        height='60vh'
        justifyContent='center'
        spacing={7}
      >
        <img
          src={srcImage}
          width='90%'
        />
        <Typography variant="subtitle1" width='80%' textAlign='justify'>
          {errorMessage}
        </Typography>
      </Stack>
    </Container>
  )
}