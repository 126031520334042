import { Grid, Card, CardMedia, CardContent, Typography, CardActions, Button, Divider, ButtonBase, Stack, Box } from '@mui/material';
import { Instagram } from '@mui/icons-material';
import React from 'react';
import { generatePath } from 'react-router-dom';
import { INFLUENCER_PROFILE } from 'navigation/Endpoints';
import { GetInfluencersSummariesResponse } from 'shared/hooks';
import { formatNumber } from 'shared/utils';

interface InfluencerSummaryCardProps {
    influencerSummary: GetInfluencersSummariesResponse;
}

export const InfluencerSummaryCard = (
  { influencerSummary }: InfluencerSummaryCardProps
) => {

  const profilePath = generatePath(INFLUENCER_PROFILE, { influencerId: influencerSummary._id });

  // const priceRangeText = influencerSummary.maxPrice && influencerSummary.minPrice ? `${influencerSummary.minPrice}€ - ${influencerSummary.maxPrice}€`: 'Price unknown'
  const priceRangeText = influencerSummary.maxPrice && influencerSummary.minPrice ? 'Open to see prices' : 'Prices unknown'

  return (
    <Grid item lg={3} md={4} sm={6} xs={12} textAlign='center'>
      <Card sx={{ overflow: 'visible', cursor: 'pointer' }}>
        <ButtonBase
          sx={{ display: 'block' }}
          href={ profilePath }
        >
          <CardMedia
            component="img"
            loading='lazy'
            src={influencerSummary.linkToProfilePicture}
            alt={`${influencerSummary.username} profile picture`}
            sx={{ position: 'relative', display: 'inline', top: -20, width: '90%'}}
          />
          <CardContent sx={{mt: -3}}>
            <Typography gutterBottom variant="h6" overflow="hidden">
              {influencerSummary.username}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {influencerSummary.name}
            </Typography>
            <Stack direction='row' justifyContent='space-around' mt={2}>
              <Box>
                <Typography variant="subtitle2">
                  {formatNumber(influencerSummary.followerCount)}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  followers
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2">
                  {formatNumber(influencerSummary.followsCount)}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  following
                </Typography>
              </Box>
            </Stack>
          </CardContent>
          <Divider /> 
          <CardActions>
            <Typography color='primary' variant="body2">
              { priceRangeText }
            </Typography>
            
            <Button startIcon={<Instagram color='secondary' />} href={influencerSummary.linkToProfile} rel="noreferrer" sx={{ marginLeft: 'auto' }}>
              <Typography color='secondary'>
                {influencerSummary.platformType}
              </Typography>
            </Button>
          </CardActions>
        </ButtonBase>
      </Card>
    </Grid>
  )
}
        