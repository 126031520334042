import { ButtonProps } from '@mui/material';
import React from 'react';
import { InfluencerButton } from './InfluencerButton';

interface InfluencerSubmitButton extends ButtonProps {
  label?: string,
  loading?: boolean
}

const InfluencerSubmitButton = (props: InfluencerSubmitButton) => (
  <InfluencerButton
    label={props.label || 'Submit'}
    type='submit'
    color='primary'
    {...props}
  />
)

export { InfluencerSubmitButton }