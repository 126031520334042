import React from 'react';
import { useInfluencerProfile } from 'shared/contexts';
import { InfluencerCard } from 'components';
import { Box, Typography } from '@mui/material';

export const InfluencerProfilePromotedBrands = () => {
  const { influencerProfile } = useInfluencerProfile()

  if (!influencerProfile) {
    return (<></>);
  }

  return (
    <InfluencerCard
      title='Recetly Promoted Brands'
    >
      {influencerProfile.promotedBrands?.map(brand => (
        <Box key={brand}>
          <Typography variant='body2' display='inline-block'>{brand}</Typography>
        </Box>
      ))}
    </InfluencerCard>
  )
}