import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import { useAuth } from 'shared/hooks';
import { LoginForm } from './features';
import { useUser } from 'shared/contexts';
import { useNavigate, useLocation } from 'react-router-dom';
import { HOME } from 'navigation/Endpoints';

export const LoginPage = () => {
  const { loading: authLoading, facebookLogin, googleLogin } = useAuth();
  const { user } = useUser();
  const { state } = useLocation()
  const navigate = useNavigate();
  
  useEffect(() => {
    user.name && navigate(state?.redirectTo || HOME, {replace: true})
  }, [user])
  
  return (
    <Box alignItems='center' display='flex' justifyContent='center' width={1} height='50vh'>
      <LoginForm
        facebookLogin={facebookLogin}
        // instagramLogin={instagramLogin}
        googleLogin={googleLogin}
        loading={authLoading}
      />
    </Box>
  )
}