import { Grid, Typography } from '@mui/material';
import React from 'react';
import { InfluencerAsyncCreationForm, InfluencerAsyncCreationFormInputs } from '../influencerAsyncCreationForm';
import { FormProvider, useForm } from 'react-hook-form';
import { usePostInfluencerAsync } from 'shared/hooks';

export const InfluencerAsyncCreation = () => {
  const formMethods = useForm<InfluencerAsyncCreationFormInputs>();
  const { loading, apiCall } = usePostInfluencerAsync();

  const onSubmit = (formValues: InfluencerAsyncCreationFormInputs) =>
    apiCall({
      username: formValues.username,
      platformType: 'Instagram'
    }, 'Influencer Created!');

  return (
    <Grid container spacing={3} textAlign='center' justifyContent='center' alignItems='center'>
      <Grid item xs={12}>
        <Typography variant='h6' mt={3}>Create influencer asynchronous way</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormProvider {...formMethods}>
          <InfluencerAsyncCreationForm
            onSubmit={onSubmit}
            loading={loading}
          />
        </FormProvider>
      </Grid>
    </Grid>
  )
}