import { INFLUENCER_CREATE, ABOUT_US, CONTACT_US, PRIVACY_POLICY, DASHBOARD, INFLUENCER_CREATE_ASYNC, BLOGS } from 'navigation/Endpoints';
import { User } from 'shared/contexts/userContext/userContextTypes';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const adminNavigationItems: NavigationItem[] = [
  { label: 'Dashboard', endpoint: DASHBOARD },
  { label: 'Blog', endpoint: BLOGS },
  { label: 'Add Influencer', endpoint: INFLUENCER_CREATE },
  { label: 'Add Influencer Async', endpoint: INFLUENCER_CREATE_ASYNC },
  { label: 'About Us', endpoint: ABOUT_US },
  { label: 'Contact Us', endpoint: CONTACT_US }
]

const userNavigationItems: NavigationItem[] = [
  { label: 'Dashboard', endpoint: DASHBOARD },
  { label: 'Blog', endpoint: BLOGS },
  { label: 'About Us', endpoint: ABOUT_US },
  { label: 'Contact Us', endpoint: CONTACT_US }
]

const guestNavigationItems: NavigationItem[] = [
  { label: 'Dashboard', endpoint: DASHBOARD },
  { label: 'Blog', endpoint: BLOGS },
  { label: 'About Us', endpoint: ABOUT_US },
  { label: 'Contact Us', endpoint: CONTACT_US }
]

const footerNavigationItems: NavigationItem[] = [
  { label: 'Privacy Policy', endpoint: PRIVACY_POLICY },
  { label: 'Instagram', endpoint: 'https://www.instagram.com/trendmyad/', Icon: InstagramIcon },
  { label: 'Email', endpoint: 'mailto:info@trendmyad.com', Icon: MailOutlineIcon },
  // TODO Cookie declaration does not work
  // { label: 'Cookie Declaration', endpoint: COOKIE_DECLARATION }
]

interface NavigationItem {
  label: string,
  endpoint: string,
  Icon?: React.ElementType
}

const getUserNavigationItems = (user: User): NavigationItem[] => {
  if (user.isAdmin) {
    return adminNavigationItems
  } else if (user.userAccessToken) {
    return userNavigationItems
  }
  return guestNavigationItems
}

export { getUserNavigationItems, NavigationItem, footerNavigationItems}
