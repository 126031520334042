import { FormHelperText, InputLabel, ListItemText, MenuItem, Select, SelectProps } from '@mui/material';
import React from 'react';
import { SFormControl } from './styled';
import { FieldError, FieldName, FieldValues, Merge, RegisterOptions, UseFormRegister } from 'react-hook-form';

type InfluencerSelectProps = SelectProps & {
  name: string
  label: string
  selectItems: string[]
  errors?: Merge<FieldError, (FieldError | undefined)[]>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: UseFormRegister<any>
  isRequired?: boolean
}

export const InfluencerSelect = (props: InfluencerSelectProps) => {
  const validationOptions: RegisterOptions<
    FieldValues,
    FieldName<FieldValues>
  > = {};
  
  if (props.isRequired) {
    validationOptions.required = `${props.label} is required`;
  }
  
  const register = props.register ? props.register(props.name, validationOptions) : {};

  return (
    <SFormControl
      disabled={props.disabled}
      fullWidth
      variant="outlined"     
    >
      <InputLabel
        id={`${props.label}-select-label`}
        sx={{ overflow: 'visible' }}
      >
        {props.label}
      </InputLabel>
      <Select
        id={`${props.label}-select`}
        labelId={`${props.label}-select-label`}
        // TODO remove inline styles
        MenuProps={{ style: { maxWidth: 0, maxHeight: 300, position: 'absolute', }, disableScrollLock: true, }}
        error={!!props.errors}
        defaultValue={props.defaultValue || (props.multiple ? [] : '')}
        multiple={props.multiple}
        renderValue={(selected: string[] | string) => typeof selected === 'string' ? selected : selected.join(', ')}
        {...register}
        {...props}
      >
        <MenuItem key={''} value={''}>
          <ListItemText primary={''} />
        </MenuItem>
        {props.selectItems.map((item) => (
          <MenuItem key={item} value={item}>
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText id="component-error-text">{props.errors?.message}</FormHelperText>
    </SFormControl>
  )
}