import React from 'react'
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Typography } from '@mui/material'
import GoogleIcon from '@mui/icons-material/Google';
import { useAuth } from 'shared/hooks';
import { useUser } from 'shared/contexts';
import { LogoutOutlined } from '@mui/icons-material';
import { InfluencerTooltipIcon } from 'components/buttons';

export const InfluencerDrawerUserBox = () => {
  const { loading: authLoading, googleLogin, logOut } = useAuth();
  const { user } = useUser();

  return (
    <> {user.name
      ? (
        <List>
          <ListItem>
            <Typography variant='subtitle2' paddingRight={1}>{user.name}</Typography>
            <InfluencerTooltipIcon
              disabled={authLoading}
              iconComponent={<LogoutOutlined />}
              onClick={logOut}
              tooltipTitle={'Log out'}
            />
          </ListItem >
        </List>
      ) : (
        <List>
          <ListSubheader>Login Or Sign Up</ListSubheader>
          {/* <ListItem disablePadding>
            <ListItemButton disabled={authLoading} onClick={facebookLogin}>
              <ListItemIcon sx={{paddingRight: 1}}>
                <FacebookRoundedIcon/>
              </ListItemIcon>
              <ListItemText primary='Facebook' />
            </ListItemButton>
          </ListItem> */}
          <ListItem disablePadding>
            <ListItemButton disabled={authLoading} onClick={googleLogin}>
              <ListItemIcon sx={{paddingRight: 1}}>
                <GoogleIcon/>
              </ListItemIcon>
              <ListItemText primary='Gmail' />
            </ListItemButton>
          </ListItem>
        </List>
      ) }
    </>
  )
}