import React from 'react';
import { InfluencerSelect, InfluencerSubmitButton } from 'components';
import { useFormContext } from 'react-hook-form';
import { InfluencerTextField } from 'components/inputs/InfluencerTextField';
import { FormProps } from 'shared/utils';

export interface InfluencerBasicInfoFormInputs {
  platform: string;
  username: string;
}

export const InfluencerBasicInfoForm = (props: FormProps<InfluencerBasicInfoFormInputs>) => {

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useFormContext<InfluencerBasicInfoFormInputs>();

  return (
    <form onSubmit={handleSubmit(props.onSubmit)}>
      <InfluencerSelect
        disabled={props.loading}
        errors={errors.platform}
        isRequired
        label='Platform'
        name='platform'
        register={register}
        // selectItems={platforms.responseData || []}
        selectItems={['Instagram']}
      />
      <InfluencerTextField
        disabled={props.loading}
        errors={errors.username}
        isRequired
        label='Influencer Username'
        name='username'
        register={register}
      />
      <InfluencerSubmitButton
        label='Submit Profile'
        disabled={props.submitDisabled || props.loading || !isValid}
        loading={props.loading}
      />
    </form>
  )
}