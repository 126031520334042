import React from 'react';
import { Typography } from '@mui/material';

export interface InfluencerInfoNoteProps {
  influencersCount: number;
}

export const InfluencerInfoNote = () => {

  return (
    <Typography variant="body2" color="text.secondary" textAlign="center">
      *You have access to information on up to 36 influencers. To explore additional influencers, refine your search filters accordingly.
    </Typography>
  );
}
