import React from 'react'
import GoogleIcon from '@mui/icons-material/Google';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { Paper, Typography, Box, Stack } from '@mui/material'
import { InfluencerAvatar, InfluencerButton } from 'components'
import { withLoading } from 'shared/hoc';

interface LoginFormImplProps {
  facebookLogin(): Promise<void>,
  googleLogin(): Promise<void>
};

const LoginFormImpl = (props: LoginFormImplProps) => {
  const { googleLogin } = props;
  
  return (
    <Stack alignItems='center' spacing={4} component={Paper} padding={2} maxWidth={500} width={1}>

      <Box display='contents'>
        <InfluencerAvatar size='medium'>
          <LockOutlinedIcon />
        </InfluencerAvatar>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
      </Box>

      {/* <InfluencerButton
        label='Login using Instagram'
        onClick={instagramLogin}
        startIcon={<InstagramIcon />}
        variant="outlined"
      /> */}
      {/* <InfluencerButton
        label='Login using Facebook'
        onClick={facebookLogin}
        startIcon={<FacebookRoundedIcon />}
        variant="outlined"
      /> */}
      <InfluencerButton
        label='Login using Google'
        onClick={googleLogin}
        startIcon={<GoogleIcon />}
        variant="outlined"
      />
    
    </Stack>
  )
}

export const LoginForm = withLoading(LoginFormImpl);