import React from 'react';
import { useInfluencerProfile } from 'shared/contexts';
import { InfluencerAttributeValuePair, InfluencerCard } from 'components';

export const InfluencerProfileAbout = () => {
  const { influencerProfile } = useInfluencerProfile()

  if (!influencerProfile) {
    return (<></>);
  }

  return (
    <InfluencerCard
      title='About'
    >
      <InfluencerAttributeValuePair
        attribute='Name'
        spaceBetween
        value={influencerProfile.name}
      />
      <InfluencerAttributeValuePair
        attribute='Gender'
        spaceBetween
        value={influencerProfile.gender || 'N/A'}
      />
      <InfluencerAttributeValuePair
        attribute='Age interval'
        spaceBetween
        value={influencerProfile.ageMin ? `${influencerProfile.ageMin}-${influencerProfile.ageMax}` : 'N/A'}
      />
      <InfluencerAttributeValuePair
        attribute='Country'
        spaceBetween
        value={influencerProfile.country || 'N/A'}
      />
      <InfluencerAttributeValuePair
        attribute='Category'
        spaceBetween
        value={influencerProfile.category || 'N/A'}
      />
    </InfluencerCard>
  )
}