import { useContext } from 'react';
import { InfluencerContext } from './influencerContext';

export const useInfluencer = () => {
  const influencerContext = useContext(InfluencerContext);

  if (!influencerContext) {
    throw new Error('useUser must be used within an UserProvider');
  }

  return influencerContext;
};