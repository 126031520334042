import { useEffect, useState } from 'react';
// import { LogOutResponse, LoginResponse, useFacebookLogin } from 'react-facebook-login-hook';
// import { singletonHook } from 'react-singleton-hook';
import { useUser } from 'shared/contexts';
import { useGetCurrentUser } from './requests';
import { GoogleLoginResponse, useGoogleLogin } from '@leecheuk/react-google-login'

// TODO Additionally, developers are required to delete data obtained through the API within a reasonable period of time after it is no longer needed for the intended purpose.
// TODO app id to env variables
// const useFacebookLoginSingletone = singletonHook(
//   {
//     ready: false,
//     busy: true,
//     logIn: async () => { return {} as LoginResponse},
//     logOut: async () => { return {} as LogOutResponse },
//     getProfile: async () => { 
//       //
//     }
//   },
//   () => useFacebookLogin({ appId: '227290306873112' })
// )

export const useAuth = () => {
  const { user, setUser } = useUser();
  const { responseData: userFromService, apiCall: getUserFromService } = useGetCurrentUser();
  const [ loading, setLoading ] = useState(true);

  // const { ready: fbReady, busy: fbBusy, logIn: fbLogin, logOut: fbLogOut, getProfile } = useFacebookLoginSingletone();

  const onGoogleLoginSuccess = (response: GoogleLoginResponse) => {
    setTimeout(() => logOut(), Number(response.tokenObj.expires_in) * 1e3);
    setUser({ name: response.profileObj.name, userAccessToken: response.tokenId })
    setLoading(false);
  }

  const onGoogleLoginFailure = (error: unknown) => {
    console.error(error);
    setLoading(false);
  }

  const { loaded: gLoaded, signIn: gLogin } = useGoogleLogin({
    clientId: '310612228528-gnvtuip38ej5lpbphi28skoq9ltvhsce.apps.googleusercontent.com',
    onSuccess: onGoogleLoginSuccess,
    onFailure: onGoogleLoginFailure,
    cookiePolicy: 'single_host_origin',
    responseType: 'code,token',
    pluginName: 'Trend My Ad'
  });


  useEffect(() => {
    if (gLoaded) {
      setLoading(false);
    }
  }, [gLoaded]);

  useEffect(() => {
    if (user?.userAccessToken) {
      getUserFromService(undefined, undefined, {'user-access-token': user.userAccessToken})
    }
  }, [user.userAccessToken]);

  useEffect(() => {
    if (userFromService) {
      setUser(userFromService)
    }
  }, [userFromService]);

  const facebookLogin = async () => {
    console.log('facebook login pressed')
    // setLoading(true)
    // fbLogin().then(response => {
    //   if (response?.status !== 'connected') return;
    //   setTimeout(() => logOut(), Number(response.authResponse.expiresIn) * 1e3);
    //   getProfile().then(profile => { 
    //     if (profile && typeof profile === 'object' && 'name' in profile && typeof profile.name === 'string') {
    //       setUser({ name: profile.name, userAccessToken: response.authResponse.accessToken})
    //     }
    //   }
    //   ).catch(error => console.error(error))
    // }).catch(error => console.error(error))
  }

  const googleLogin = async () => {
    setLoading(true)
    gLogin()
  }

  const logOut = () => {
    setUser({})
    // fbLogOut()
  }

  return {loading, facebookLogin, googleLogin, logOut}
}