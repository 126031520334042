import { StepIconProps } from '@mui/material';
import { InfluencersStepIconRoot } from './InfluencersStepIconRoot'
import React from 'react';
import { CheckCircle } from '@mui/icons-material';

export const InfluencersStepIcon = (props: StepIconProps) => {
  const { active, completed, className } = props;

  return (
    <InfluencersStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <CheckCircle className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </InfluencersStepIconRoot>
  );
}