import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ABOUT_US, BLOG, BLOGS, CONTACT_US, COOKIE_DECLARATION, DASHBOARD, HOME, INFLUENCER_CREATE, INFLUENCER_CREATE_ASYNC, INFLUENCER_PROFILE, LOGIN, PRIVACY_POLICY } from 'navigation/Endpoints';
import { DashboardPage } from 'pages/Dashboard';
import { AboutUsPage } from 'pages/AboutUs';
import { InfluencerCreation } from 'pages/InfluencerCreation';
import { InfluencerAsyncCreationPage } from 'pages/InfluencerAsyncCreation';
import { InfluencerProfilePage } from 'pages/InfluencerProfile';
import { LoginPage } from 'pages/Login';
import { ProtectedRoute } from './ProtectedRoute';
import { ContactUsPage } from 'pages/ContactUs';
import { PrivacyPolicyPage } from 'pages/PrivacyPolicy';
import { ErrorNotFoundPage } from 'pages/Error';
import { CookieDeclarationPage } from 'pages/CookieDeclaration';
import { HomePage } from 'pages/Home';
import { BlogsPage } from 'pages/Blogs';
import { BlogPage } from 'pages/Blog';

export const RouterConfig = () => {
  return (
    <Routes>
      <Route path={HOME} element={<HomePage />} />
      <Route path={DASHBOARD} element={<DashboardPage />} />
      <Route path={ABOUT_US} element={<AboutUsPage />} />
      <Route path={CONTACT_US} element={<ContactUsPage />} />
      <Route path={COOKIE_DECLARATION} element={<CookieDeclarationPage />} />
      <Route
        path={INFLUENCER_CREATE}
        element={
          <ProtectedRoute>
            <InfluencerCreation />
          </ProtectedRoute>
        }
      />
      <Route
        path={INFLUENCER_CREATE_ASYNC}
        element={
          <ProtectedRoute>
            <InfluencerAsyncCreationPage />
          </ProtectedRoute>
        }
      />
      <Route path={INFLUENCER_PROFILE} element={<InfluencerProfilePage />} />
      <Route path={BLOGS} element={<BlogsPage />} />
      <Route path={BLOG} element={<BlogPage />} />

      <Route path={LOGIN} element={<LoginPage />} />
      <Route path={PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
      <Route path="*" element={<ErrorNotFoundPage />} />
    </Routes>
  );
};

export const fullWidthRoutes = [
  HOME
]
