import React, { Children } from 'react';
import { Box, Divider, Paper, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Property } from 'csstype';

type InfluencerHomePaperParams = {
  backgroundColor?: string;
  backgroundImage?: string;
  children?: React.ReactNode;
  direction: 'row' | 'column';
  header?: React.ReactNode;
  footer?: React.ReactNode;
  hideDivider?: boolean;
  spacing?: number;
  width?: string | number;
  textAlign?: Property.TextAlign;
}

export const InfluencerHomePaper = (params: InfluencerHomePaperParams) => {
  const { children, backgroundColor, direction, header, footer } = params
  const theme = useTheme()

  const biggerThanMd = useMediaQuery(theme.breakpoints.up('md'));
  const smallerThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const isBackgoroundDark = !!backgroundColor && ![theme.palette.background.default, theme.palette.background.paper].includes(backgroundColor);

  const stackDirection = direction === 'row' && biggerThanMd ? 'row' : 'column'
  const dividerOrientation = direction === 'row' && biggerThanMd ? 'vertical' : 'horizontal'
  const dividerColor = isBackgoroundDark ? 'white' : theme.palette.secondary.main
  const textColor = isBackgoroundDark ? 'white' : theme.palette.secondary.main
  const textAlign = stackDirection === 'row' && params.textAlign ? params.textAlign : 'center'

  const isChildSingleElement = Children.toArray(children).length === 1
   
  return (
    <Paper elevation={0} square sx={{
      textAlign: textAlign,
      p: smallerThanMd ? 1 : 5,
      backgroundColor: backgroundColor,
      color: textColor,
      width: 1
    }}>
      <Box paddingY={2}>
        {header}
      </Box>
      {isChildSingleElement ? children : (
        <Stack
          alignItems='center'
          direction={ stackDirection }
          spacing={params.spacing || 5} 
          margin={smallerThanMd ? 1 : 5}
          justifyContent='space-evenly'
          divider={params.hideDivider ? null : <Divider orientation={dividerOrientation} color={dividerColor} flexItem />}
          sx={{ backgroundImage: `url(${params.backgroundImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center' }}
          width={params.width ?? 'auto'}
          display='inline-flex'
        >
          { children }
        </Stack>
      ) }
      { footer }
    </Paper>
  );
}